.swiper {
    // height: 120px !important;
    width: 1350px !important;
    background-color: transparent;
    position: absolute;
    bottom: 20px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .append-buttons {
    text-align: center;
    margin-top: 20px;
  }
  
  .append-buttons button {
    display: inline-block;
    cursor: pointer;
    border: 1px solid #007aff;
    color: #007aff;
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    margin: 0 10px;
    font-size: 13px;
  }
  
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: #059e70;
  }
  
  .mySlider {
    cursor: pointer;
    p {
      padding: 0;
      margin: 0;
    }
  }
  
  
  
  
  
  .mySlider .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider_menu_title {
    font-family: 'Noto Sans';
    color:#ffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 400;
  }
  
  .active_menu_slider {
    font-family: 'Noto Sans';
    font-weight: 400;
    display: flex;
    color:#35f7bd;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* Add any other styles for the active slide */
  }
  
  .hidden {
    display: none;
  }
  
  @media (max-width: 1305px) {
    .swiper {
      height: 120px !important;
      width: 1000px !important;
    }
  }
  
  @media (max-width: 1060px) {
    .swiper {
      height: 120px !important;
      width: 900px !important;
    }
  }
  @media (max-width: 1005px) {
    .swiper {
      height: 120px !important;
      width: 800px !important;
    }
  }
  @media (max-width: 865px) {
    .swiper {
      height: 120px !important;
      width: 750px !important;
    }
  }
  
  @media (max-width: 805px) {
    .swiper {
      display: none;
    }
  }
  