
.Sport-details-container {
    padding-left: 40px;
    padding-top: 2.5rem;
    padding-bottom: 2rem;
}

.Sport-details-header {
    font-size: 20px;
    font-weight: 600;
}
.Sport-details {
    display: grid;
    gap: 30px;
   // grid-template-columns: repeat(3, 1fr);
    padding-left: 40px;
    padding-top: 10px;
}
.Sport-details > div > img {
    padding-bottom: 15px;
}
.sport-label {
    margin-bottom: 1rem;
}
.sport-label::before {
    content: "*";
    color: red;
    float: right;
    margin-left: 4px;
}
