.fullback-bg {
    background-image: url("../../asserts/Header/full-bg-image.webp");
    background-attachment: fixed;
    background-size: cover;
}

.banner {
    background-image: url("../../asserts/OrganisationalStructure/banner.png");
    background-size: cover;
    // min-height: 400px;
    width: 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.organigation_banner_height {
    height: 55vh;

}



.banner1-text {
    // padding-top: 20px;
    // margin-top: 40px;
    font-size: 58px;
    text-align: center;
}

.organizational {

    color: #011D46;

    font-family: "Noto Sans";
    //font-size: 3.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.structure {
    font-weight: 700;

}

.client-name span {
    padding: 10px;
    transition: 0.25s;
    font-size: larger;
}

.nav-button {
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
    color: inherit;
    padding: 10px;
    font-size: 24px;
}

.nav-button.active {
    background: rgb(5, 158, 112);
    color: #fff;
}

@media (max-width:599px) {
    .banner1-text{
        font-size:36px;
        margin-top: 32%;
    }
}