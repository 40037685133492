.flex-center {
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
}
.registration-container {
    max-width: 100%;
    min-height: 100vh;
    // max-height: 800vh;
    // padding: 20px;
    // border: 2px solid red;
    background: url("../../asserts/login/loginRightMask.png") no-repeat center;
    background-size: cover;
    background-color: #6851ba;
    overflow: hidden;
}
// .d-flex {
//     padding-bottom: 30px;
//     img {
//         margin-left: 25px;
//         margin-top: 30px;
//     }
// }

.p-10 {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.registerOptions {
    margin-top: 10px;
}
.loginSection {
    margin-top: 20px;
    display: flex;
    color: white;
   
}
.login-btn-registration {
    width: 110px;
    height: 40px;
    font-size: 15px;
    color: white;
    background-color: #5d41af;
    border: none;
    border-radius: 5px;
    margin-left: 15px;
}

.login_field_left_reg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.user_info .bi-person-circle {
    color: white;
    font-size: 2.5rem;
 
}

.user_info {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    margin-right: 1rem;
}

.user_name {
    color: white;
}

.bi-box-arrow-right {
    color: white;
    font-size: 2rem;
}

.user-name-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.logout-btn {
cursor: pointer;
}
.login_btn:disabled {
    background-color: #9686c5;
    
}

@media (min-width: 992px) {
    .w-lg-350px {
        width: 350px !important;
    }
    .position-lg-fixed {
        position: fixed !important;
    }
    .mt-lg-7 {
        margin-top: 1.75rem !important;
    }

    div, main, ol, pre, span, ul {
        scrollbar-width: thin;
        scrollbar-color: var(--bs-scrollbar-color) transparent;
    }
}

@media (max-width: 770px){
    .registration-container{
        min-height:0px;
    }
}