.bank-details-container {
    max-width: 90vw;
    padding-bottom: 2rem;
    padding-left: 3rem;
    padding-top: 5rem;
}

.atom-header {
   padding-bottom: 2rem;
}

.bank-details-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: start;

}


.cancelled-cheque {
    display: flex;
}

.bank-label {
    color: black;
    margin-bottom: 1rem;
}
.bank-label::before {
    content: "*";
    color: red;
    float: right;
    margin-left: 4px;
}
.borderBttn{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.bd-1-input {
    width: 350px;
}
@media (max-width: 792px) {
    .bank-details-container {
        max-width: 100vw;
        padding-left: 20px;
    }
    .bank-details-fields {
        grid-template-columns: repeat(1, 1fr);
      
    }
}

