.personal-details-registerpage-main-container {
    padding: 30px;
    background-color: white;
    height: 80vh;
    overflow-y: auto;
 }
 
 .personal-details-register-as {
     display: grid;
     grid-template-columns: 66.66% 30.33%;
     align-items: center;
     justify-content: center;
     align-content: center;
     gap:20px;
     .left-side{
         display: grid;
         grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
         gap: 20px;
         .email-password-1{
             display: flex;
             flex-direction: column;
         }
     }
 
     .image-container{
         display: flex;
         flex-direction: column;
         align-items: center;
     
     }
 }
 .label-pd {
    margin-left: 10px;
    max-width: fit-content;
    padding: 1rem 0rem 1rem 0rem;
 }

 .label-pd::before {
  content: "*";
  float: right;
  color: red;
  padding-left: 4px;
 }
 
 .input-pd {
    background-color: #f8f9fa;
 }

 .personal-details-email-password {
     input {
         background-color: #f8f9fa;
         max-width: 100%;
     }
 }
 
 // .personal-details-register-as > div:nth-child(3) {
 //     grid-row-start: 1;
 //     grid-row-end: 3;
 //     grid-column-start: 3;
 //     grid-column-end: 5;
 // }
 // .personal-details-register-as > div:nth-child(1) , .personal-details-register-as > div:nth-child(2),
 // .personal-details-register-as > div:nth-child(4), .personal-details-register-as > div:nth-child(5){
 //     padding-right: 50px;
 // }
 
 .pd-1 {
     img {
         padding-bottom: 10px;
     }
     input {
         border: none;
         padding: 10px;
     }
 }
 
 @media (max-width: 760px) {
     // .personal-details-register-as{
     //     grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
     // }
 
     // .personal-details-email-password input {
     //     background-color: #f8f9fa;
     //     max-width: 60% !important;
     // }
 
 
     .personal-details-register-as {
         grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
         gap:20px;
         .left-side{
             display: grid;
             grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
         }
     }
 
 }