.Education-detail-container {
  padding-top: 1rem;
  padding-left: 5rem;
  background-color: white;
  padding-bottom: 1rem;
}

.education-details-header {
  font-weight: 600;
  font-size: 20px;
}

.education-details-lower-container {
  max-width: 80%;
  // height: 80vh;
  margin-top: 30px;
  margin-bottom: 20px;
}

.education-details-lower-container-1,
.education-details-lower-container-2 {
  max-width: 100%;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}
.bold-text {
  max-width: fit-content;
  font-weight: bold;
}

.borderchange {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.buttonHeight {
  height: 37.6px;
  margin-top: -2px;
  margin-top: -5px;
}

.buttonHeight:disabled {
  color: white;
}

.viewButton {
  margin-left: 2px;
  margin-top: -5px;
  margin-bottom: 2px;
  height: 37.6px;
}
.uploadButton {
  margin-left: 2px;
  // margin-top: -2px;
  margin-bottom: 2px;
  height: 37.6px;
}
.education-details-lower-container-1 div {
  background-color: #f8f9fa;
  padding-top: 5px;
  padding-left: 20px;
}

.education-details-lower-container-2 div {
  background-color: #f8f9fa;
  margin-top: 5px;
}
.input-type-accept {
  font-size: 14px;
  max-width: fit-content;
}

.education-details-lower-container-1 > div:nth-child(2),
.education-details-lower-container-2 > div:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.input-file-education-details {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  button {
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
    max-width: 100%;
    color: #f8f9fa;
    background-color: #5d41af;
  }
  button:disabled {
    background-color: #8776b8;
    cursor: not-allowed;
  }
}

.input-file-education-details button:hover {
  background-color: #3a2380;
}

.astrick {
  max-width: fit-content;
}

.astrick::before {
  content: "*";
  float: right;
  color: red;
  padding-left: 4px;
}

@media (max-width: 592px) {
  .Education-detail-container {
    padding-left: 30px;
  }
  .education-details-lower-container-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.upload-warning {
  color: red;
  font-size: 14px;
  margin: 1rem 0px 0px 0px;
  font-weight: 700;
}
