.arrow_container_forger_password {
    margin: 5px 0px 0px 180px;
    cursor: pointer;
    p {
        text-decoration: underline;
    }
}

.jharkhand_logos_login {
    display: flex;
    gap: 40px;
    position: absolute;
    top: 60px;
    left: 100px;
  }