



.map-container {
  height: calc(100vh - 0px);
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;

}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.map_card_height_title{
  
  
}

.custom-marker {
  width: 24px;
  height: 35px;
  background-image: url('../../asserts/Infrastructure/map.png');
  background-size: cover;
}

.custom-marker-zoomed {
  width: 34px;
  height: 35px;
  background-image: url('../../asserts/Infrastructure/zoomMap.png');
  background-size: cover;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.area-text {
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  padding: 8px;
  background-image: linear-gradient(to bottom, rgb(1, 29, 70), rgb(40, 69, 113));

  border-radius: 5px;
}



// ***********Pop-Up Map*********************


.popup-container-right-top {
  position: absolute;
  left: 20px;
  z-index: 1000;
  background-image: linear-gradient(to bottom, rgb(202, 205, 242), rgb(219, 221, 231));
  border-radius: 10px;
}

.img-popup {
  border-top-left-radius: 100px;

  // border: 10px solid red;
  img {
    height: 40vh;
    width: 500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}





.flex {
  display: flex;
  margin: 0px;
  padding: 0px;
  max-height: 50px;
}

.justify-between {
  justify-content: space-between;
  margin-top: 0px;
  padding: 0px;
}

.items-end {
  align-items: flex-end;
}

.mt-4 {
  margin-top: 0px !important;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  // max-height: 60px;
}

.text-center span {
  max-height: 100%;
}

.pt-12 {
  padding-top: 3rem;
}

.p-8 {
  padding: 2rem;
}

.popop-container {
  height: 500px;
  width: 500px;
  z-index: 1000000;
  position: absolute;
  right: 10%;
  top: 19%;
  // animation: slideIn 0.5s ease forwards;

  @media (max-width: 940px) {
    width: 100%;
    height: 100vh;
  }
}
.popup-container-image {
  width: 400px;
  height: 250px;
  @media (max-width: 900px) { 
    width: 100%;
    height: 100vh;
  }
}

@keyframes slideIn {
  from {
    top: 100%;
  }
  to {
    top: 15%;
  }
}

.p-8 {
  color: black !important;
}

.explore-btn {
  // position: absolute;
  bottom: 10px;
  left: 30px;
  border: none;
  width: 20%;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 33px;

}

.text-ocean {
  p {
    font-size: 15px;
    margin-bottom: 70px;
  }

  h3 {
    font-size: 24px;
    font-weight: bold;
  }
}



.closeIcon {
  font-size: 40px !important;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.left-bottom-btns {
  position: absolute;
  display: flex;
  bottom: 40px;
  left: 30px;
  max-width: 100%;
}

.switchRegion {
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  max-width: 170px;
  text-align: center;
}

.switchRegion:hover {
  background-color: rgb(1, 29, 70);
  color: white;
  cursor: pointer;
}

.location-name {
  background: linear-gradient(to bottom, #c5d785, #e6e8de);
  color: black;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  max-width: 800px;
  text-align: center;
  margin-left: 10px;
  animation: changeGradient 50s infinite;
}
@keyframes changeGradient {
  0% {
    background: linear-gradient(to bottom, #a28cd9, #cac4d7);
  }
  50% {
    background: linear-gradient(to bottom, #d688af, #d3bac7);
  }
  100% {
    background: linear-gradient(to bottom, #8bb582, #c5dcc0);
  }
}

.selection-region-heading {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

.switchregion-popup {
  background-color: white;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  gap: 10px;
  animation: slideInRegion 0.6s forwards;
}

@keyframes slideInRegion {
  from {
    top: 100%;
  }
  to {
    top: 60%;
  }
}


.switch-region-divs {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  display: flex;
  gap: 20px;
  overflow-x: auto;
}

.coordinate-image {
  background-color: rgba(255, 255, 255, 0.5); /* Initial background color with low opacity */
  transition: background-color 0.3s ease; 
  img {
    border-radius: 10px;
    cursor: pointer;
  }

}

.region-close {
  cursor: pointer;
}

.clear-btn {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 10;
  .closeIcon {
  
    transform: translateX(-20%);
    animation: slideOut 0.6s forwards; /* Apply animation */
  }
  
  @keyframes slideOut {
    from {
      top: 0; /* Start from top */
    }
    to {
      top: 100%; /* Move to the bottom */
    }
  } 
}


.regional-details-popup {
  position: absolute;
  z-index: 200000000;
  bottom: 0;
  left: 30px;
  max-width: 42%;
  height: 100vh;
  padding: 15px;
  background-color: white;
  overflow-y: auto;
  div {
    margin-top: 20px;
    margin-left: 10px;
  }
  img {
    border-radius: 10px;
  }
}

.closebutton-regional-details {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 8px;
    background-color: rgb(5, 158, 112);
    color: white;
    border-radius: 5px;
    border: none;
    word-spacing: 10px;
    max-width: 40%;
    cursor: pointer;
  }
  button:hover {
    background-color: rgb(10, 91, 67);
  }
}

.heading-regional-details {
  font-size: 35px;
  font-weight: bold;
  margin-top: 30px;
}
.mapPoint-flex {
  display: flex;
}
.green-layout {
  background-color: rgb(209, 255, 241);
  padding: 15px;

}

.get-direction-btn {
  border: none;
  padding: 8px;
  max-width: fit-content;
  background-color: rgb(5, 158, 112);
  border-radius: 5px;
  color: white;
}
.get-direction-btn:hover{
  background-color: rgb(8, 113, 81);
}