
.athletes_profile-contianaer {
  background-color: #014833;
  background-image: url("../../asserts/AthleteProfile/bgIsolation.png");
  min-height: 580px;
  // background-position: -350px 0px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.athletes-profile-tabs-section {
  width: 96%;
  max-width: 1440px;
  // border: 1px solid green;
  margin: auto;
  padding: 3%;
}

.flag-Img {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  text-align: center;
  img {
    height: auto;
    text-align: center;
    display: inline-block;
    width: 23%;
    max-width: 23%;
    min-width: 20%;
    height: auto;
  }
}

.left-athlete-content {
    position: absolute;
    bottom: 80px;
    left: 80px;
    color: white;

    p {
        margin: 10px 0px 10px 0px;
        padding: 0.2rem;
    }
    h3 {
        font-weight: 600;
        padding-bottom: 4px;
    }
    button {
        background-color: #059e70;
        color: white;
        border-radius: 5px;
        border: none;
        margin: 10px 0px 0px 0px;
        padding: 5px 10px 5px 10px;
    }
}

.social-media-handles {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    right: 100px;
    margin-top: 80px;
    

    cursor: pointer;
    img {
        width: 40px;
        
    }
}

.css-19kzrtu {
  padding: 0 !important;
}


.rank-number {
  position: absolute;
  right: 150px;
  top: 90px;
  p {
    font-size: 400px;
    color: rgb(52, 109, 92);
    font-family: fantasy;
  }
}

.rankImg {
  position: absolute;
  right: 270px;
 bottom: 30px;
  img {
    max-width: 60px;
  }
}

@media (max-width: 1180px) { 

  .left-athlete-content {

    bottom: 180px;
    left: 40px;
   
  }
 

  .flag-Img {
    img {
      width: 65%;
    }
  }
  .social-media-handles {
    top: 100px;
    right: 20px;
  }
  .rank-number {
    position: absolute;
    right: 70px;
    top: 90px;
    p {
      font-size: 200px;
    }
  }
  .rankImg {
  position: absolute;
  right: 125px;
 bottom: 260px;
  img {
    max-width: 25px;
  }
}
}

@media (max-width: 720px) { 

  .flag-Img {
    img {
      width: 100%;
    }
  } 
  .social-media-handles {
    top: 100px;
    right: 20px;
  }
  .rank-number {
    position: absolute;
    right: 70px;
    top: 90px;
    p {
      font-size: 200px;
    }
  }
  .rankImg {
  position: absolute;
  right: 125px;
 bottom: 260px;
  img {
    max-width: 25px;
  }
}
}


@media (max-width: 430px) {

    
.left-athlete-content {
    bottom: 210px;
    left: 30px;
}
 
.flag-Img {
    img {
      width: 100%;
    }
  } 
  .social-media-handles {
    top: 100px;
    right: 20px;
  }
  .rank-number {
    position: absolute;
    right: 70px;
    top: 90px;
    p {
      font-size: 250px;
    }
  }
  .rankImg {
  position: absolute;
  right: 135px;
 bottom: 205px;
  img {
    max-width: 30px;
  }
}
  
}
