.athletes-container {
  background-image: url("../../asserts/Athlete/athleteBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 1920px;
  height: 600px;
  display: flex;
  gap: 20px;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.athletes-head {
  color: #fff;
  text-align: center;
  font-family: Koulen;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem;
  padding-bottom: 4rem;
}

.heading-text p {
  font-size: 32px;
  font-weight: 500;
}

.athletes_height_title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.athletes_title {
  color: #fff;

  text-align: center;
  font-family: "Koulen", sans-serif;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem; /* 125% */
}

.athletes-flex {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  gap: 10px;
}

.athletes-flex div p {
  margin: 5px 10px 5px 10px;
  padding: 10px;
  border-radius: 15px;
}

.athletes-heading {
  color: #000;
  
  font-family: "Noto Sans";
  font-size: 3rem;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
 
  margin: 5% 0% 0% 4%;
}

.athlete-search-field {
  position: relative;

  input {
    position: relative;
    padding: 8px;
    border: none;
    outline: none;
    padding-left: 30px;
    width: 400px;
    // left: 35%;
    top: 70px;
    background-color: transparent;
    background-image: url("../../asserts/Athlete/searchInput.png");
  }

  img {
    position: absolute;
    top: 90px;
    /* left: 800px; */
    transform: translateY(-50%);
    right: -30px;
  }
}
.centered-children {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-grow: 1;
  margin-left: 200px;
}
.athelte-dropdown:last-child {
  margin-left: auto; 
}



.athlete-dropdown-select {
  width: 150px;
  padding: 10px;
  border: none;
  color: grey;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  background-image: url("../../asserts/Athlete/Vector\ 3.png");
  background-size: cover;
}

.dropdown-arrow {
  position: absolute;
  top: 20px;
  right: 30px;
}

.athlete-dropdown-select:focus {
  outline: none;
}

.gender-option {
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 10px;
}

.gender-option.selected {
  background-color: #059e70;
  color: white;
}

//Table

.table2-athlete {
  max-width: 92vw;
  overflow: auto;
  font-size: 15px;
  margin-left: 70px;
  padding-bottom: 40px;
}

.Athlete-DivContainer {
  position: relative;
  /* Required for absolute positioning of pseudo-elements */
  background-color: white;
  display: grid;
  grid-template-columns: 2fr 1.8fr 1.3fr 1fr 1.3fr;
  align-items: center;
  min-width: fit-content;
  padding: 20px;
  margin-top: 20px;
}

/* Cut out bottom-left corner */
.Athlete-DivContainer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  /* Adjust size as needed */
  border-right: 20px solid transparent;
  /* Adjust size as needed */
  border-bottom: 20px solid #f6f7f9;
  /* Adjust color as needed */
  border-left: 20px solid #f6f7f9;
  /* Adjust color as needed */
}

/* Cut out top-right corner */
.Athlete-DivContainer::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 20px solid #f6f7f9;
  /* Adjust color as needed */
  border-right: 20px solid #f6f7f9;
  /* Adjust color as needed */
  border-bottom: 20px solid transparent;
  /* Adjust size as needed */
  border-left: 20px solid transparent;
  /* Adjust size as needed */
}

.Athlete-TableHeader {
  background-color: #059e70;
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: 2.2fr 1.4fr 1.2fr 0.9fr 1.2fr;
  padding: 15px 20px;
  min-width: fit-content;
}

.Athlete-TableHeader::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f6f7f9;
  border-left: 15px solid #f6f7f9;
}

.Athlete-TableHeader::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 15px solid #f6f7f9;
  border-right: 15px solid #f6f7f9;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
}

@media (max-width: 1290px) {
  .athlete-search-field {
    img {
      top: 90px;
      left: 750px;
    }
  }

  // .athelte-dropdown {
  //   position: absolute;
  //   right: 50px;
  //   bottom: -15px;
  // }
}
@media (max-width: 1200px) {
  .athlete-search-field {
    img {
      top: 90px;
      left: 650px;
    }
  }
  // .athelte-dropdown {
  //   position: absolute;
  //   right: 50px;
  //   bottom: -10px;
  // }
}

@media (max-width: 993px) {
  .athlete-search-field {
    input {
      left: 25%;
      top: 70px;
    }
    img {
      top: 90px;
      left: 500px;
    }
  }

  .athletes-flex {
    margin-left: 30px;
    justify-content: flex-start;
  }



  .table2-athlete {
    max-width: 100vw;
    margin-left: 0px;
    padding-bottom: 40px;
    font-size: 12px;
  }

  .Athlete-TableHeader {
    grid-template-columns: 100px 100px 100px 150px 100px;
  }

  .Athlete-DivContainer {
    grid-template-columns: 100px 100px 100px 150px 100px;
  }
}

@media (max-width: 773px) {
  .athlete-search-field {
    input {
      width: 300px;
      left: 10%;
      top: 70px;
    }
    img {
      top: 90px;
      left: 300px;
    }
  }

  .athletes-flex {
    margin-left: 10px;
    justify-content: flex-start;
  }
  .centered-children {
    margin-left: 0;
    gap: 5px;
  }


}

@media (max-width: 430px) {
  .athlete-search-field {
    input {
      width: 200px;
      left: 3%;
      top: 70px;
    }
    img {
      top: 90px;
      left: 150px;
    }
  }

  .athletes-flex {
    display: none;
  }
}
