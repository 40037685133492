.scholarship_container_bg{
    background-image: url('../../asserts/asserts/Home/Slider_image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
     height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   height: 80vh;
   flex-direction: column;
}

.banner_heading{
    color: #FFF;
text-align: center;
font-family: "Koulen", sans-serif;
font-size: 5rem;
font-style: normal;
font-weight: 400;
line-height: 6.25rem; /* 125% */
}

.banner_menu_list_wrappper{
    list-style-type: none;
}

.card_container_wrap{
   padding-bottom: 2.9rem;
   padding-top:1rem;
}

.banner_list_menu{
    color: #FFF;
text-align: center;
font-family: "Noto Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 2.1875rem; /* 218.75% */
}

.scholarship_card_bg{
    background-image: linear-gradient(180deg, #000F0A 0%, #014F37 100%);
}

.card_heading{
    color: #FFF;
text-align: center;
font-family: "Noto Sans";
font-size: 2.5rem;
font-style: normal;
font-weight: 700;
line-height: 3.625rem; /* 120.833% */
}

.scholar_para{
    color: #FFF;
text-align: center;
font-family: "Noto Sans";
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 144.444% */
}

.scholar_title{
    color: #FFF;
font-family: "Noto Sans";
font-size: 1.875rem;
font-style: normal;
font-weight: 700;
line-height: 2.5rem; /* 133.333% */
}

.scholarship_card_container{
    background-color: transparent!important;
    border:1px solid #55E7BB!important;
}

.scholar_card_para{
    text-align: left;
    font-size: 1rem;
}

.scholar_apply_btn{
    border-radius: 0.5rem;
background: var(--ggggg, #059E70);
color: #FFF;
font-family: "Noto Sans";
font-size: 1rem;
font-style: normal;
font-weight: 600;
line-height: 1.625rem; /* 130% */
padding:10px 20px;
border:1px solid #059E70;

}

.multi_overlap_img{
    height: 60vh;
}



.accordion_card_list_wrapper{
    background-color: #059E70;
    list-style-type: none;
    cursor: pointer;
}

.accordion_card_list{
   
    color: #FFF;
    font-family: "Noto Sans";
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.625rem; /* 108.333% */
    border-bottom: 1px solid #55E7BB;
}
.accordion_card_des_wrapper{
    border: 1px solid #55E7BB;
}

.eligibilty_title{
    color: #FFF;
font-family: "Noto Sans";
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 144.444% */

}
.custom-list {
    list-style-type: disc; /* Ensure the list style is set to disc */
    color: #fff;
}

