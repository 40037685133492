.btn-style{
    background: white;
    color: black;
    border-radius: 8px;
    padding: 10px 35px;
    border: unset;
    
}
.btn-style:hover{
    background-color: #0f5d1d !important;
    color: #fff !important;
}