.footer-main-bg {
  background: #222222;
  color: #ddd;
}

.footer-main {
  background-image: url("../../asserts/AboutUs/Group\ 64.png");

  display: flex;
  margin-right: 5px;

  background-repeat: no-repeat;
  background-position: 26% -250%;
  background-size: min(30%, 600px) auto;
  height: auto;
  min-height: calc(530px + 60px);
}

.footer-head {
  font-size: 20px;
  color: white;
  padding-bottom: 5px;
  font-weight: 700;
}

.footer-navigations:hover {
  color: #4cb97d;
  transition-duration: 0.5s;
}

.footer-sm {
  font-size: 18px;
}

.chatbot-section {
  display: flex;
  justify-content: space-between;
}
.jharkhandlogo{
  height: 110px 
}
.chatbot-fixed {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  cursor: pointer;
}

.chatbot-above-footer {
  position: relative;
  bottom: auto;
  right: auto;
  cursor: pointer;
}

.footer-main p {
  color: #b5b5c7;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.sports-main {
  display: flex;
  gap: 15px;
}
.align-text {
  padding-left: 10 !important;
}
.align-text p:hover {
  cursor: pointer;
  text-decoration: underline;
}
.text-grey {
  color: #b5b5c7;
}
.footer-uttharakhand_rajya {
  margin-top: 60px;
  height: 110px;
}
.footer-part-1 {
  padding-right: 45px !important;
}
@media (min-width: 768px) {
  .notMobile {
    display: none;
  }
  .onlyMobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .notMobile {
    display: block;
  }
  .onlyMobile {
    display: none;
  }

  
}
@media (min-width: 600px) and (max-width: 767px) {
  /* CSS for small devices */
  .jharkhandlogo{
    height: 90px
  }
  .footer-head{
    font-weight:500
  }
}

/* Medium devices (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
  /* CSS for tablets */
  .jharkhandlogo{
    height: 90px
  }
  .footer-head{
    font-weight:500;
    font-size: 18px;
  }

}

/* Large devices (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  /* CSS for large desktops */
  .jharkhandlogo{
    height: 110px 
  }
  .footer-head{
    font-weight:500;
    font-size: 18px;
  }

}


// .footer-head {
//     font-size: 20px;
//     color:white;
//     padding-bottom: 5px;
//     font-weight: 700;

// }
// .footer-navigations:hover{
//     color: #aecfff;
//     transition-duration: 0.5s;
//     cursor: pointer;
// }
// .footer-sm {
//     font-size: 18px;
// }

// .footer-main p {
//     color:#b5b5c7;
// }

// .sports-main {
//     display: flex;
//     gap: 15px;
// }
// .align-text{
//     padding-left: 10 !important;
// }
// .text-grey{
//     color:#b5b5c7;
// }
// .footer-uttharakhand_rajya{
//     margin-top: 60px;
//     height:110px;
// }
// .footer-part-1{
//     padding-right: 45px !important;
// }

// .chatbot-section {
//     display: flex;
//     justify-content: space-between;
//   }

//   .chatbot-fixed {
//     position: fixed;
//     bottom: 0px;
//     right: 0px;
//     left: 0px;
//   }

//   .chatbot-above-footer {
//     position: relative;
//     bottom: auto;
//     right: auto;
//   }
// @media (min-width: 768px) {
//     .notMobile{
//         display: none;
//     }
//     .onlyMobile{
//         display: block;
//     }

// }
// @media (max-width: 768px) {
//     .notMobile{
//         display: block;
//     }
//     .onlyMobile{
//         display: none;
//     }
//     .chatbot-section  {
//         display: none;
//     }
// }

.accessbility_wrap {
  position: relative;
  display: inline-block;
}

.char_box_wrapper {
  display: none; /* Initially hide the card */
  position: absolute;
  top: -70px; /* Adjust the position as needed */
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;

  // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* Add a triangle to the bottom-right corner */
.char_box_wrapper::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Adjust this value as needed */
  left: 10px; /* Adjust this value as needed */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid white; /* Same color as the background of the card */
}

.accessbility_wrap:hover .char_box_wrapper {
  display: block; /* Show the card on hover */
}

.professional_title {
  font-family: "Noto Sans" !important;
}

.chat_play {
  background-color: #999;
  border-radius: 50%;
  /* padding: 20px; */
  color: #fff;
  width: 30px;
  height: 29px;
  text-align: center;
  font-size: 17px;
  color: black;
  margin-right: 10px;
}
