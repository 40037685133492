.myPhotoSwiper {
  max-height: 420px !important;
  min-height: 200px !important;
}

.photo-slider {
  max-width: 300px;
}

.relative {
  position: relative;
}
.relative:hover .photo-overlay {
  display: block;
  cursor: pointer;
  opacity: 1;
}

.photo-overlay {
  display: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 80px;
  opacity: 0;
  transition: opacity 0.5s ease;
  h3 {
    font-size: 20px;
    max-width: 500px;
    font-weight: bold;
    padding: 0 40px;
  }
}
.photo-slider:hover {
  filter: brightness(70%);
}

.discover-btn {
  border: none;
  background-color: white;
  font-size: 15px;
  padding: 5px;
  border-radius: 10px;
  position: absolute;
  top: 180px;
  left: 120px;
}

@media (max-width: 1287px) {
  .myPhotoSwiper {
    min-height: 200px;
  }
  .photo-slider {
    max-width: 200px;
  }
}

