.alertBoxheader{
 height: 50px;
}
.bordeCn{
  border: 2px solid black;
}
.nameHeader{
    font-weight: 500;
    font-size: 24px;
    
}
.txtAlign{
    margin-top: 20px;
    text-align: right;
}
.regHeader{
    text-align: center;
    
}
.round-button {
    background-color: #0a983a;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    //display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    transition: background-color 0.3s ease;
    margin-right: 10px;
  }
  
  .round-button:hover {
    background-color: #0056b3;
  }
  
  .round-button i {
    pointer-events: none; /* Prevent the icon from interfering with the button click */
  }
  .round-button-log {
    background-color: #E77D22;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    //display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    transition: background-color 0.3s ease;
    margin-right: 10px;
  }
  
  .round-button-log:hover {
    background-color: #0056b3;
  }
  
  .round-button-log i {
    pointer-events:none     ; /* Prevent the icon from interfering with the button click */
  }
  .divgap{
    margin-top: 15px;
  }
  .fieldText{
    font-weight: 500;
    color: #0056b3;
  }