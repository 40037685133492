.contact-header {
    // padding-left: 90px;
    padding-top: 50px;
    padding-left: 2px;
    font-size: 20px;
    font-weight: 600;
    word-spacing: 5px;
  }
  // .verifyContact-dflex {
  //   display: flex;
  //   justify-content: space-around;
  // }
  
  
  .verifyContactDetails-container {
    max-width: 100%;
  }
  // .left-container-verifycontact {
  //   max-width: 40%;
  //   margin-top: 40px;
  // }
  .email_input_wrap{
    // width: 30px;
    border: 1px solid #f5eaea;
    padding: 14px 30px;
    border-radius: 0.375rem;
    background: #FFF;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  }
  .emailField{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .get_top_title{
    height:53.78px;
    max-width: 50%;
    padding: 10px;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #5d41af;
    color: white;
    font-size: 15px;
  }
  .right-container-verifycontact {
    
  }
  .input-field-verifyContact {
    display: flex;
    flex-wrap: wrap;
  
    span {
      padding-top: 10px;
      padding-right: 5px;
      padding-left: 5px;
      margin-right: 5px;
      background-color: whitesmoke;
    }
    input {
      width: 240px;
      border: 1px solid #f5eaea;
      padding: 14px 30px;
      border-top-left-radius: 0.375rem;
      border-bottom-left-radius: 0.375rem;
      //border-radius: 0.375rem;
      background: #FFF;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
    }
    button {
      max-width: 50%;
      padding: 10px;
      border: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #5d41af;
      color: white;
      font-size: 15px;
    }
  }

  .countcd{
    width: 100px;
    margin-right: 5px;
  }
  .input_box{
    width: 40px!important;
    text-align: center;
    height: 40px;
    font-size: 20px;
    margin: 10px;
    border: 1px solid rgb(239, 233, 233);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 20px 0px;
  }

  /* Style for the select element */
.form-select {
  color: black!important; /* Set the color of the text to black */
}

/* Optional: Style for the options if needed */
.form-select option {
  color: black!important; /* Ensure the options also appear in black */
}
  .country-code-drop {
    width: 80px;
    border: 1px solid #f5eaea;
    padding: 14px 0px 14px 0px;
    border-radius: 0.375rem;
    background: #FFF;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
    color: black;
  }
  
  .recaptcha-verifyDetails {
    padding-top: 5%;
  }
  .mobile-image-container {
    padding-bottom: 10px;
  }
  .otpConfirm-verifyDetails {
    padding-top: 5%;
  }
  .didnot-get-the-code-verify {
    p {
      padding-left: 13%;
    }
  }
  .proceed-btn-verify-contact {
    margin-top: 50px;
    margin-bottom: 50px;
    button {
      width: 75%;
      padding: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #5d41af;
      color: white;
      border: none;
      border-radius: 5px;
    }
  }

  .proceed-btn:disabled{
    background-color: #726fb7;
    cursor: not-allowed; 
  }
  .label::before {
  content: "*";
  float: right;
  color: red;
  margin-left: 4px;
  }

  .error-message {
    color: red;
    padding-top: 1rem;
  }
  .get_top_title:disabled {
    background-color: #726fb7;
    cursor: not-allowed;
  }

  .resend-btn {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #5d41af;
  }
  .resend-btn:disabled {
    color: gray;
    cursor: not-allowed;
  }
  @media (max-width: 500px) {
    .left-container-verifycontact {
      max-width: 100% !important;
    }
    .right-container-verifycontact {
      max-width: 100% !important;
    }
  
    .verifyContact-dflex {
      align-items: start;
      padding: 20px;
    }
    .contact-header {
     // padding-left: 10px;
    }
  }
  
  @media (max-width: 880px) {
    .verifyContact-dflex {
      flex-direction: column;
    }
    .contact-header {
      ///padding-left: 10px;
    }
    .verifyContact-dflex {
      align-items: center;
    }
    .otp-input-reg-contact input {
      width: calc(33.33% - 20px) !important;
      padding: 0px;
    }
  }
  
  @media (max-width: 600px){
    .get_top_title{
      height:40.78px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 5px ;
      border-bottom-left-radius: 5px;
      font-size: 15px;
    }
  }