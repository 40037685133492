// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }

// .search {
//   --easing: cubic-bezier(0.4, 0, 0.2, 1);
//   --font-size: 1rem;
//   --color: var(--color-foreground);
//   --color-highlight: white;
//   --transition-time-icon: 0.2s;
//   --transition-time-input: 0.3s 0.25s;
//   border-radius: 25px;
//   border: 2px solid white;
//   display: flex;
//   align-items: center;
//   transition: border-color var(--transition-time-icon) linear,
//     padding var(--transition-time-input) var(--easing);
//   padding: 0.4rem;
// }

// .search:not(.search--expanded) {
//   --transition-time-input: 0.2s 0s;
// }

// @media (prefers-reduced-motion: reduce) {
//   .search {
//     --transition-time-icon: 0s !important;
//     --transition-time-input: 0s !important;
//   }
// }

// .search__input {
//   background: transparent;
//   border: none;
//   color: var(--color-highlight);
//   font-size: var(--font-size);
//   outline: none;
//   padding: 0.1rem;
//   transition: width var(--transition-time-input) var(--easing),
//     padding var(--transition-time-input) var(--easing),
//     opacity var(--transition-time-input) linear;
//   width: 6rem;
//   -webkit-appearance: none; /* Remove clear button in Safari */
//   -moz-appearance: none; /* Remove clear button in Firefox */
// }
// .search__input::-webkit-search-cancel-button {
//   -webkit-appearance: none; /* Remove clear button in Chrome */
// }

// .search__input::placeholder {
//   color: var(--color);
//   opacity: 0.75;
// }

// .search__icon-container {
//   height: calc(var(--font-size) + 1rem); /* Adjusted for smaller size */
//   position: relative;
//   width: calc(var(--font-size) + 1rem); /* Adjusted for smaller size */
//   color: #ffffff;
//   padding-top: 3px;
// }

// .search__label,
// .search__submit {
//   color: var(--color);
//   cursor: pointer;
//   display: block;
//   height: 100%;
//   padding: 0;
//   position: absolute;
//   width: 100%;
// }

// .search__label:hover,
// .search__label:focus,
// .search__label:active,
// .search__submit:hover,
// .search__submit:focus,
// .search__submit:active {
//   color: var(--color-highlight);
// }

// .search__label {
//   transition: transform var(--transition-time-icon) var(--easing), color 0.1s;
// }

// .search__submit {
//   background: none;
//   border-radius: 50%;
//   border: none;
//   box-shadow: 0 0 0 4px inset transparent;
//   display: none;
//   outline: none;
//   transition: color 0.1s, box-shadow 0.1s;
// }

// .search__submit svg {
//   transform: scale(0.4); /* Adjusted icon scale */
// }

// .search__submit:focus {
//   box-shadow: 0 0 0 4px inset var(--color-highlight);
// }

// .search.search--expanded .search__input {
//   width: 12rem; /* Expanded width reduced */
// }
// .search_input_phone{
//   display: none;
// }

// .search.search--expanded .search__label {
//   transform: scale(0.4); /* Adjusted scale for label */
// }

// .search.search--expanded .search__submit {
//   animation: unhide var(--transition-time-icon) steps(1, end);
//   display: block;
// }

// @keyframes unhide {
//   from {
//     height: 0;
//     opacity: 0;
//   }

//   to {
//     height: auto;
//     opacity: 1;
//   }
// }

// @media (max-width:770px){
//   .search.search--expanded .search__input {
//     width: 0rem; /* Expanded width reduced */
//   }
// }
/* Container for the search bar */
.search-container {
  position: relative;
  width: 100%;
  max-width: 250px;
  margin: 20px auto;
}

/* Search input field */
.search-input {
  width: 100%;
  padding: 10px 50px 10px 20px;
  /* Padding to create space for the icon */
  border: 2px solid white;
  /* White border */
  border-radius: 50px;
  /* Oval shape */
  background-color: transparent;
  /* Transparent background */
  outline: none;
  font-size: 16px;
  color: white;
  /* Text color inside the search input */
  transition: all 0.3s ease;
  box-sizing: border-box;
}

/* Placeholder text color */
.search-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  /* Light white placeholder color */
}

/* Input focus effect */
.search-input:focus {
  border-color: white;
  // box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}

/* Search icon SVG inside the input field */
// .search-icon {
//   position: absolute;
//   right: 15px;
//   top: 50%;
//   transform: translateY(-50%);
//   pointer-events: none;
//   /* Prevents the icon from blocking input focus */
// }
.search-icon {
  fill: white;
  width: 24px;
  height: 24px;
}


// /* Ensure SVG icon is white */
// .search-icon path {
//   fill: white;
// }

.search-button {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.search-button:hover .search-icon {
  fill: rgba(255, 255, 255, 0.7);
  /* Change icon color on hover */
}