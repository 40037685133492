.basic-details-container {
  padding-left: 50px;
  padding-top: 10px;
  padding-bottom: 0.5rem;
  max-width: 80%;
}
.widthField{
  width:70%
}

@media (max-width:770px){
  .widthField{
      width: 30%;
  }
  
}

.basic-details-container-support-staff {
  padding-top: 100px;
  padding-bottom: 40px;
}

.label {
  padding: 1rem 0rem 1rem 0rem;
}
.sport-details {
  width: 850px;

}

.bd-header {
  padding-top: 1rem;
  p {
    font-weight: 600;
    font-size: 20px;
    padding: 0rem 0rem 2rem 0rem;
  }
}

.bd-container-lower-childrens {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bd-container-lower {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.form-check-input:checked {
  background-color: #50cd89 !important;
  border-color: #50cd89 !important;
}

.form-check-input[type="radio"] {
  background-color: rgb(225, 222, 222);
}

.bd-lower-bg {
  position: absolute;
}

.bd-container-lower-child {
  display: flex;

  p {
    padding-left: 50px;
    padding-top: 10px;
  }
}

.bd-lower-img {
  position: relative;
  top: 7px;
  left: 13px;
}

.bd-lower-radio-buttons div {
  padding: 10px 10px 10px 10px;
  border: 2px dotted grey;
  border-radius: 10px;
}

.select-country-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 20px;
}

@media (max-width: 770px){
  .select-country-container {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
  }
  .bd-lower-radio-buttons div {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  
}