.contact-us-container {
  background-image: url("../../asserts/ContactUs/banner.png");
  min-height: 580px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.common-heading {
  color: #fff;
  text-align: center;
  font-family: Koulen;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px;
}
.contact-us-contents {
  display: flex;
  gap: 100px;
  max-width: 100%;
  padding: 5%;
}
.contactUs-details {
  width: 40%;
  p {
    padding-top: 20px;
    color: #000;
    font-family: "Noto Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
  }
  h3 {
    color: #212121;
    font-family: "Noto Sans";
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.625rem;
  }
}
.your-details {
  color: #000;
  font-family: "Noto Sans";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.625rem;
  max-width: fit-content;
  margin-bottom: 20px;
}
.your-details::before {
  content: "*";
  float: right;
  margin-left: 5px;
}
.contactUs-form {
  width: 60%;
}
.radio-btns {
  margin: 40px 0px 0px 0px;
  label {
    color: #000;
    font-family: "Noto Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    margin-top: 10px;
  }
}
.radio-btn {
  transform: scale(3);
  margin-right: 20px;
  border: 2px solid green;
}
.radio-container {
  margin: 40px 0px 20px 20px;
  div {
    margin: 30px 0px 30px 0px;
  }
  .form-check-input[type="radio"] {
    border: 2px solid #059e70;
    font-size: 30px;
    background-color: white;
  }
}

.input-label-radio {
  width: 30.25rem;
  height: 3.75rem;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid grey;
}
.input-file-contact-us-details {
  display: flex;
  margin: 0px 0px 30px 0px;
  button {
    width: 120px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 7px;
    font-size: 1rem;
    max-width: 100%;
    background-color: #059e70;
    border: none;
    color: white;
  }
}
/* Basic grid layout */
// .grid-container {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Makes grid responsive */
//   gap: 20px; /* Space between grid items */
//   padding: 4%;
// }

/* Grid items */
.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Make the image responsive */
.responsive-image {
  width: 100%;
  height: auto; /* Keeps the aspect ratio intact */
  max-width: 100%; /* Ensures the image doesn't overflow its container */
  object-fit: cover; /* Ensures the image covers the area without stretching */
  border-radius: 10px; /* Optional: Adds rounded corners to the image */
}

.radios-heading {
  color: #000;
  font-family: "Noto Sans";
  //font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.625rem;
}
.contactUs-input-boxes {
  padding-bottom: 40px;
  border-bottom: 1px solid #b7d4ff;
}
.fullName-input {
  max-width: 100%;
  margin: 10px 0px 0px 0px;
}

.label-contact-us {
  font-weight: 500;
  margin-top: 20px;
}
.label-contact-us::before {
  content: "*";
  color: red;
  float: right;
}
.send-btn {
  margin-top: 20px;
  button {
    border: none;
    background-color: #059e70;
    color: white;
    font-size: 0.9rem;
    padding: 7px;
    width: 100px;
    border-radius: 5px;
  }
}

.img-content {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 20px 0px 40px 0px;
  img {
    width: 654px;

  }
}
.contact-usDet{
  border-radius: 16px;
  padding: 40px 0px 0px 30px;
  background: var(--ggggg, linear-gradient(180deg, #d8f5e7 0%, #e9fdfb 100%));

}
.contact-us-state-details {
  width: 654px;
  border-radius: 16px;
  padding: 40px 0px 0px 30px;
  background: var(--ggggg, linear-gradient(180deg, #d8f5e7 0%, #e9fdfb 100%));
  h3 {
    color: #000;
    font-family: "Noto Sans";
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.8125rem;
  
  }
  p {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: "Noto Sans";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
  }
}

@media (max-width: 600px) {
  .contact-us-contents {
    flex-direction: column;
    gap: 20px;
  }
  .contactUs-details {
    width: 100%;
  }
  .contactUs-form {
    width: 100%;
  }
  .img-content {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {

  /* CSS for desktops and large screens */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal columns */
    gap: 20px;
  }

  .cardHeight {
    height: 360px;
  }
  .tenderBtn-mobile {
    display: none;
  }

}