.about-container {
    background-image: url("../../asserts/AboutUs/about-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
    width: 100%;
    min-height: 80vh;
    position: relative;
}

.heading-text p {
    font-size: 32px;
    font-weight: 500;
   
}

// .blue-bg {
//     background: linear-gradient(#021812,#013f2d) !important;
// }
.introduction-container{
    background-image: url('../../asserts/AboutUs/Background.png');
    background-repeat: no-repeat;
    // background-size: min(50%, 600px) auto;
    // background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
    width: 100%;
    min-height: 80vh;

}
.Introduction-right {
    position: relative;
}

.Introduction-right img {
    width: 100%;
}

.main-text-container{
    padding-bottom: 150px;
    padding-top: 60px;
    text-size-adjust: auto;
    padding-right: 60px !important;
}
.col-talent-1 {
 color: white;
 padding-top: 56px; 
}
.empty {
    height: 350px;
}

.Introduction-right .img-2,
.Introduction-right .img-1 {
    margin-bottom: -10px;
}

.about_title{
    font-size: 1.3rem;
    font-weight: 700;
    font-family: 'Noto Sans';
    line-height: 26px;
}


.img_container {
   
    .right_side_img {
        position: relative;

        & img {
            &:first-child {
                width: 61rem;
                margin-bottom: 30px;
            }

            &:last-child {
                position: absolute;
                width: 350px;
                right: 0;
                top: 50%;
                transform: translate(-50%, -50px);
            }
        }
    }
}
.objective_heading{
    color: #011D46;
font-family: "Noto Sans";
font-size: 3rem;
font-style: normal;
font-weight: 700;
line-height: 1.625rem; /* 54.167% */
}
.objective_section {
    background: url(../../asserts/AboutUs/world_doted.png) no-repeat;
    background-position: 100% 10%;
    background-size: 65% 50%;
   
}
.Introduction-right{
    display: flex;
}

.objective_para{
    color: #212121;
font-family: "Noto Sans";
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 144.444% */
}

.right-image img,
.left-image img{
    width: 90%;
}

.slider-cont {
    margin-top: 0px;
    padding-top: 0px;
    position: absolute;
    bottom: 160px;
    left: 40px;
}
.about_banner_title{
    font-family: 'Noto Sans';
    font-weight: 400;
    font-size: 1.875rem;
   text-transform:capitalize;
    color:#FFF;
    line-height: 1.625rem;
}

.banner_headings{
    font-family: 'Noto Sans';
    font-weight: 700;
    font-size: 2.2rem;
   text-transform:capitalize;
    color:#fff;
   
}

.about_us_container{
    margin-top: 250px;
    // border:1px solid yellow;
}

.introduction_title{
    color: #FFF;
font-family: "Noto Sans";
font-size: 1.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 86.667% */
}

.intro_para{
    color: #FFF;
text-align: center;
font-family: "Noto Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: 2.1875rem; /* 145.833% */
}
.templebg_img_section{
    position: relative;
}

.margin_left_title{
    margin-left: 3.5rem;
}

.temple_img{
    position: absolute;
    bottom: -240px;
    left: 105px;
    width: 100%;
}

.uttrakhan_section_wrapper{
    margin-top: 9.5rem!important;
}

.about_us_temple_margin_top{
    margin-top: 8rem!important;
   
   
}

.custom-image {
    height: auto;
    max-width: 600px;
    /* margin-left: -26px; */
    width: 100%;
  }
  
  .image-containers {
    display: flex;      /* Flexbox to center the image */
    justify-content: center;
    align-items: center;
    height: 100%; 
    width: 100%;      /* Ensures the container takes the full height of the column */
  }

  .center_title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:30px;
  }

  .white_image_wrapper{
    position: relative;
  }

  .browen_temple_wrapper{
    position: absolute;
    z-index: 1;
    top: 44px;
    right: -51px;
  }

//   .jharkhand_browen_temple_wrapper{
//     position: absolute;
//     z-index: 1;
//     top: 65px;
//     right: -120px;

//   }

  .browen_temple_wrapper{
    position: absolute;
    z-index: 1;
    top: 44px;
    right: -51px;
  }

  .slider_img_title{
    width:300px;
  }

@media(min-width: 775px){
    .Introduction-right{
        flex-direction: column-reverse;
    }

    .img_container{
        display: grid;
        grid-template-columns: 430px 1fr;
        margin-bottom: 50px;
    }

    
    
}

@media (max-width:774px){
    .img_container img{
        width: 100%;
    }

    .img_container .right_side_img img:first-child{
        width: 100%;
    }

    .img_container img:first-child{
        display: none;
    }

    .jharkhand_browen_temple_wrapper {
       // position: absolute;
        z-index: 1;
        margin-top: 20px;
        top:0;
        right:0
    }
}


@media (max-width:1000px){
    .jharkhand_browen_temple_wrapper {
      //  position: absolute;
        z-index: 1;
        margin-top: 20px;
        top:0;
        right:0
    }
}



.mixed-images{
    margin-top: 140px !important;
}


@media only screen and (max-width: 600px) {

    .banner_heading {
        font-size: 1.7rem;
        text-align: center;
    }
        
    
    .temple_img {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
}

.browen_temple_wrapper {
    right: 0;
    position: static;
    margin-top: 10px;
}

.mobile_hero_section{
    padding: 0;
}

.swiper{
    // display: block!important;
    width: 395px !important;
    bottom: 95px!important;
}

.swiper-slide {
    width: 150px!important;
}

.introduct_margin_top{
    margin-top: 12rem;
}

.about_us_temple_margin_top{
    margin-top: 16rem!important;
}

.temple_img {
    top: 0px;
}
.img_tile{
    width: 100%;
}

.uttrakhan_section_wrapper{
    margin-top: 19.5rem!important;
}


  }
  @media (min-width:1200px){
    .banner_headings{
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 3.5rem;
       text-transform:capitalize;
        color:#fff;
       
    }
    .jharkhand_browen_temple_wrapper{
        position: absolute;
        z-index: 1;
        top: 65px;
        right: -120px;
    
      }
  }