.sign_in_right_container {
  // background: url("../../asserts//login/login_left_gradient.png") no-repeat center;
  // background-size: cover;
  display: flex;
  color: white;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
.sub-heading{
  font-weight: 300;
}

.loginLeft {
  background: url("../../asserts/login/bg_left_v2.png") no-repeat center;
  height: 100vh;
  width: 60%;
  padding: 0;
  position: relative;
  -webkit-clip-path: ellipse(90% 100% at -5% 50%);
  z-index: 3;
  background-size: cover;
}
.jharkhand_logos_login {
  display: flex;
  gap: 40px;
  position: absolute;
  top: 60px;
  left: 100px;
}

.loginRightv2 {
  position: absolute;
  right: 0px;
  height: 100vh;
  width: 60%;
  padding: 30px;
  background: url("../../asserts/login/bg_rightsvg.svg");
  background-size: cover;
  background-color: #6a3ec6;
}

.loginRight h1 {
  font-weight: 600;
  margin-left: 13%;
}
.check{
  background-color: #6a3ec6;
  border-color: #fff;
}

.sign_in_btn_container > p:nth-child(2) {
  position: relative;
}

.loginRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5rem !important;
  span {
    margin-left: 150px;
    margin-bottom: 30px;
  }
}

.loginRight > p {
  font-size: 14px;
  margin-bottom: 60px;
  text-align: center;
  margin-left: 150px;
}

.loginRight form {
  width: 80%;
  text-align: center;
}

.input_form_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  margin-left: 20%;
}

.input_form_container input {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
}

.remember_forgot_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 40px;
  width: 80%;
  margin-left: 150px;
}

.remember_container > input {
  margin-right: 5px;
}

.remember_forgot_container > p {
  margin: 0;
}

.loginRight form button {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  border: none;
  width: 80%;
  margin-left: 150px;
}

.loginRight form button.submit-btn {
  font-weight: 600;
  background: linear-gradient(45deg, #422da8, #422da8);
  color: white;
}

.login-with-adhar {
  margin-top: 20px;
  font-weight: 600;
  background: linear-gradient(45deg, #422da8, #422da8);
  color: white;
}

.or-section {
  margin-top: 50px;
}

.sign_in_btn_container p {
  font-size: 14px;
  text-align: center;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  position: relative;
}

.dont-have-account-login {
  margin-top: 20px;
  margin-left: 150px;
  cursor: pointer;
}

.sign_in_btn_container p {
  width: 80%;
  margin-left: 150px;
  position: relative;
}

.sign_in_btn_container p::before,
.sign_in_btn_container p::after {
  background: white;
  height: 1px;
  width: 45%;
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
}

.login_error_message {
  color: Red;
  text-align: left;
}

.sign_in_btn_container p::before {
  left: 0;
}

.sign_in_btn_container p::after {
  right: 0;
}

.link-style {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 900px) {
  .sign_in_right_container {
    flex-direction: column-reverse !important;
  }

  .loginLeft {
    width: 100%;
    position: relative;
    // -webkit-clip-path: ellipse(100% 50% at 40% 40%);
    -webkit-clip-path: ellipse(100% 100% at 40% 40%);
    background-size: cover;
  }

  .loginRightv2 {
    left: 0px;
    top: 500px;
    width: 100vw;
    height: 120%;
    background: url("../../asserts/login/loginResponsive.png");
    background-size: cover;
    z-index: 5;
  }
  .loginRight {
    .or {
      margin-left: 60px;
    }
  }

  .loginRight {
    span {
      margin-left: 0px;
      margin-bottom: 30px;
    }

    form {
      width: 100%;
      align-items: center;
    }
    .input_form_container {
      margin-left: 60px;
    }
    p {
      margin-left: 10px;
    }
    .input_form_container {
      margin-left: 60px;
    }
    .remember_forgot_container {
      margin-left: 60px;
    }
  }

  .loginRight form button {
    margin-left: 0px;
  }
  .dont-have-account-login {
    margin-left: 0px;
    cursor: pointer;
  }

  .loginRight h1 {
    margin-top: 80px;
    margin-left: 0px;
  }
  .sub-heading {
    margin-left: 0px !important;
  }
}

@media (max-width: 720px) {
  .loginRight {
    height: 100% !important;
    width: 100vw;
    align-items: center;
    margin-top:80px
  }
  .loginheader{
    margin-top: 100px;
  }
}

@media (max-width: 490px) {
  .loginRight {
    padding: 0px !important;
  }
  .loginheader{
    margin-top: 100px;
  }

  .loginRight p {
    margin-bottom: 30px;
    padding: 0px 5px;
  }

  .loginRight h1 {
    margin-top: 30%;
  }

  .loginRight {
    height: 100% !important;
    .input_form_container {
      margin-left: 40px;
    }
    .remember_forgot_container {
      margin-left: 40px;
      align-items: flex-start;
      margin: 10px 0px 0px 40px;
    }
    .or {
      margin-left: 40px;
    }
  }
}
