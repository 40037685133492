.continue-btn {
    margin: 30px 10px;
}

.jharkhand_logos_login {
    display: flex;
    gap: 40px;
    position: absolute;
    top: 60px;
    left: 100px;
  }