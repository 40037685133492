.cheif-guest-board {
  position: relative;
  isolation: isolate;
  padding: 30px 60px;
}

.image_pattern {
  position: absolute;
  z-index: -1;
  inset: 0;
}
.image {
  position: absolute;
  inset: 0;
  z-index: -1;
}
.person_image {
  transform: translate(90px, 0);
  aspect-ratio: 1;
  height: 100%;
}
.text-area {
  p {
    margin: 0px;
  }
}
.profile-name {
    color: #212121;
    font-family: "Noto Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; 
    margin-bottom: 20px;
}
.profile-position, .profile-address, .profile-state {
    b {
        font-weight: 700;
    }
}

@media (max-width: 992px) {
  .person_image {
    transform: translate(60px, 0);
  }

  .cheif-guest-board {
    padding: 30px;
  }
  .text-area h1 {
    font-size: 1rem;
  }
  .text-area p {
    font-size: 12px;
  }
}

@media (max-width: 778px) {
  .person_image {
    transform: translate(40px, 0);
  }
  .banner-text {
    font-size: 28px;
  }
}
