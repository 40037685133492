.overView-comp-athlete {
  // background-color: rgb(244, 244, 235);
  background-color: #f6f7f9;
  h4 {
    font-family: Noto Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    margin: 0px;
    padding: 20px 0px 10px 0px;
  }
  h1 {
    font-family: Noto Sans;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    // border: 1px solid red;
    padding: 10px 0px 20px 0px;
  }

  .main-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .filters {
      display: flex;
      flex-direction: row;
      gap: 10px;
      height: min-content;
      justify-content: center;
      padding-top: 30px;
    }
  }

  .biography {
    p {
      font-size: 14px;
      text-align: justify;
    }
  }
  .major-titles {
    .title-card-container {
      display: flex;
      gap: 20px;
      width: 100%;
      flex-wrap: wrap;
      .card {
        min-width: 460px;
        min-height: 180px;
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;

        .logoContainer {
          width: 180px;
          height: 100%;
          padding-left: 10px;
          display: flex;
          justify-content: center; /* Horizontally center the image */
          align-items: center;
          img {
            max-width: 140px;
          }
        }
        .dataCotainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-wrap: wrap;
          padding-left: 10px;
          .eventName {
            max-width: 260px;
            font-family: Noto Sans;
            .medal {
              margin-bottom: 4px;
            }
          }
        }
      }
      .card:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
      }
    }
  }

  .dropdown{
    padding: 7px;
    margin-left: 10px;
    width: 200px;
  }



  .athlete-ranking-table-content {
    padding: 10px 0px;
    max-width: 100%;
    // margin-left: 20px;
    .table2 {
      width: 89vw;
      //   overflow: auto;
      font-size: 15px;
      max-width: 100%;
      overflow: hidden;
      //   .bodyContainer{
      //     overflow: hidden;
      //   }
      .athlete-fopTableHeader {
        background-color: #059e70;
        align-items: center;
        max-width: 100%;
        color: #fff;
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 15px 20px;
        min-width: fit-content;
      }

      .athlete-fopTableHeader ::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 15px solid #f6f7f9;
        border-left: 15px solid #f6f7f9;
      }

      .athlete-fopTableHeader ::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 15px solid #f6f7f9;
        border-right: 15px solid #f6f7f9;
        border-bottom: 15px solid transparent;
        border-left: 15px solid transparent;
      }
      .athlete-fopDivContainer {
        position: relative;
        /* Required for absolute positioning of pseudo-elements */
        background-color: white;
        max-width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        min-width: fit-content;
        padding: 2px;
        margin-top: 10px;
      }

      /* Cut out bottom-left corner */
      .athlete-fopDivContainer::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        /* Adjust size as needed */
        border-right: 20px solid transparent;
        /* Adjust size as needed */
        border-bottom: 20px solid #f6f7f9;
        /* Adjust color as needed */
        border-left: 20px solid #f6f7f9;
        /* Adjust color as needed */
      }

      /* Cut out top-right corner */
      .athlete-fopDivContainer::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 20px solid #f6f7f9;
        /* Adjust color as needed */
        border-right: 20px solid #f6f7f9;
        /* Adjust color as needed */
        border-bottom: 20px solid transparent;
        /* Adjust size as needed */
        border-left: 20px solid transparent;
        /* Adjust size as needed */
      }
    }
  }
}
@media screen and (max-width: 870px) {
  .main-heading {
   flex-direction: column-reverse !important;
   padding-top: 25px;
  }
  .dropdown{
    width: 100% !important;
    margin-left: 0px !important;
  }
  .title-card-container {
    justify-content: center;
    .card {
      min-width: 380px !important;
      margin: 0px;
      padding: 0px;
    }
  }
  .verView-comp-athlete .athlete-ranking-table-content {
    margin-left: 0px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 570px) {
  .main-heading {
    .filters {
      width: 100%;
      padding-top: 0px !important;
      flex-direction: column !important;
    }
  }
}
