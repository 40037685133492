


.registration-container-main {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  
}


.left-registration-conatiner {
  width: 30%;
  min-height: 100vh;
  position: relative;
}

.right-registration-conatiner {
  // width: 70%;
  position: relative;

  // height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.backAndnext-Btn {
  margin-top: 30px;
  position: static;
  width: 90%;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  bottom: 50px;
}
.back-btn,
.next-btn {
  margin-left: 90px;

  cursor: pointer;
}
.view-form-btn {
  margin-left: 10px;
}

.adharInput_title{
  width: 100%;
  border: 1px solid #f5eaea;
  padding:14px 30px;
  border-radius: 0.375rem;
  background: #FFF;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
}  
.Continue_btn:disabled {
  background-color: #726fb7;
  margin: 0rem 0rem 0rem 1rem;
  cursor: not-allowed;
}
  
@media (max-width: 880px) {
  
  .registration-container-main {
    flex-direction: column;
  }

  .left-registration-conatiner {
    max-width: 100%;
    min-width: 100%;
  }
  .backAndnext-Btn {
    position: static;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .back-btn,
.next-btn {
  margin-left: 20px;
}
  .right-registration-conatiner {
    margin-top: 0px;
    max-width: 100%;
    min-width: 100%;
  }
}

.already_account_title{
  color: #FFF;
font-family: "Noto Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 130% */
}

.login_btn{
  border-radius: 0.375rem;
background: #50CD89;
color: #FFF;
text-align: center;
font-family: "Noto Sans";
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.625rem; /* 130% */
border:1px solid #50CD89;
padding: 6px 15px;
}


@media (max-width: 700px) {
.registration-container-main {
  max-width: 100%;
  min-height: 0vh;
  //display: flex;
  // border: 1px solid red;
}
}