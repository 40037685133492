.menu-popup-main {
  background-color: #202020;
  color: black;
  position: fixed;
  width: 100%;
  left: 0;
  height: 100vh;
  top: 0;
  transition-duration: 0.3s;
  // padding: 20px;
  z-index: 100099;
  overflow-y: auto;
  cursor: pointer;
}

.default_container_wrapper{
  background: #020202;
  z-index: 1;
}

.menu-images {
  height: 89px;
  /* padding-left: 10px; */
  /* padding-bottom: 60px; */
  width: 95px;
  margin-top: 30px;
}

.cls-btn button {
  padding: 10px 18px;
  background: white;
  color: black;
  border: unset;
  border-radius: 50%;

}

.cls-btn {
  display: flex;
  justify-content: start;
}

.cls-btn>button:hover {
  background-color: #067151;
  color: white;
}

.menu-inner-left {
  transition-duration: 0.5s;
}


// .menu-inner-left p:hover {
//   color: #007aff;
//   transition-duration: 0.5s;
// }

.menu-popup-main {
  transition-duration: 0.5s;
}

// .menu-inner-left {
//   padding-top: 50px;
//   padding-left: 30px;
// }

.menu_hover_title:hover{
  border-radius: 0.625rem;
  background: #FFF;
  color: #212121;
font-family: "Noto Sans";
font-size: 1.875rem;
font-style: normal;
font-weight: 500;
line-height: normal;



}


.menu_hover_title:hover p{
  color: #212121;

}
.menu-inner-left p {
  color: #FFF;
font-family: "Noto Sans";
font-size: 1.20rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.left_bar_arrow_icon{
  color: #ffff;
  font-size: 1.2rem!important;
}

.menu_hover_title:hover .left_bar_arrow_icon {
  color: #212121 !important;
  font-size: 1.2rem!important;
}

.image-container {
  border-top: 1px solid #b7d4ff;
  margin-top: 30px;
}

.menu-inner-left p svg {
  opacity: 0;
  transition: 0.3s ease-in;
}

.menu-inner-left p:hover svg {
  opacity: 1;
  color: #007aff;
  padding-left: 4px;
}

.menu-inner-down {
  border-top: 1px solid #b7d4ff;
  margin-top: 30px;
  padding-top: 30px;
}

.input-search-menu-popup {
  background-color: #B7D4FF;
  outline: none;
  border: none;
  margin-left: 10px;
  margin-top: 2px;
}

.input-search-menu-popup::-webkit-search-cancel-button {
  display: none; 
}

.menu-inner-deptofSports {
  display: flex;
  justify-content: space-between;
  // max-width: 300px;

}

.menu-popup-arrowButton {
  margin-top: 6px;
}

.search-button-menu-popup {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #B7D4FF;
  width: 355px;
  height: 48px;
  flex-shrink: 0;
  h6{
    margin-top: 5px;
    margin-left: 15px;
  }
   div {
    margin-top: 10px;
   }
   svg {
    margin-right: 20px;
   }
}

.menu-popup-options {
  margin-top: 50px;
  margin-left: -120px;
  p {
    color: grey;
    font-size: 17px !important;
  }
}

.menu-popup-latest-options {
  p {
    color: grey;
    font-size: 16px !important;
    padding-left: 5px !important;
  }
}

.submenu_headeing{
  color: #FFF;
font-family: "Noto Sans";
font-size: 3.2rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.submenu_list{
  color: #FFF;
font-family: "Noto Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 400;
line-height: normal;
list-style-type: none;
margin-bottom: 20px;
}

// @media (min-width: 779px) {
//   .menu-inner-left p {
//     font-size: 22px;
//     font-weight: 600;
//   }
// }

.menu_link{
  text-decoration: none;
  color: #FFF;
font-family: "Noto Sans";
font-size: 1.3rem;
font-style: normal;
font-weight: 400;
line-height: normal
}

.left_bar_arrow_icon{

}

// .scroll_title{
//   overflow-y: scroll;
// }

.submenu-container {
  position: relative; /* Required for absolute positioning */
 ; /* Hide overflowing content */
}

 .slide-in {
  overflow-y: scroll;
  height: auto;
  border-radius: 8px;
  position: relative;
  // top: 50px;
  opacity: 0;
  animation: 0.5s ease 0s slide-and-fade-in;
  animation-fill-mode: forwards;
}

@keyframes slide-and-fade-in {
  0% {
    right: 100px;
    opacity: 0;
  }

  100% {
  right: 0px;
    opacity: 1.0;
  }
}










@media (max-width: 579px) {
  .menu-popup-main {
   width: 100%;
  }
}