.latest-news-content-main {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  transition: transform 0.25s ease;
  position: relative;
  margin: 0;
  height: 100%;
  text-decoration: none;
  // border: 1px solid rgba(0, 0, 0, 0.125);

  .latest-news-card-image {
    width: 100%;
    // width: 486px;
    height: 282px;

    background-size: cover;
    background-position: center;
    border-radius: 18px;
    padding: 0;
    position: relative;

    .latest-news-badge {
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(
        --hhhhh,
        linear-gradient(90deg, #19417a 0%, #0b9444 100%)
      );
      color: #ffffff;
      font-weight: 700;
      border-right: 0.4rem solid var(--blue-fff, #19417a);
      width: 150px;
      height: 52px;
      flex-shrink: 0;
      border-radius: 0px 0px 0px 18px;
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      display: flex;
      justify-content: center; /* Center text horizontally */
      align-items: center; /* Center text vertically */
      text-align: center; /* Center text alignment */
    }
  }

  .latest-news-content-body {
    padding: 1.5rem;

    .latest-news-date-news {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      align-items: center;
      gap: 10px;

      .latest-news-date-text {
        text-transform: uppercase;
        color: #000 !important;
        margin-top: 0 !important;
        margin-left: 0 !important;
        font-size: 1.2rem;
      }
    }

    .latest-news-card-title {
      margin-bottom: 1.6rem;
      color: #000;
      font-size: 1.2rem;
      overflow: hidden;
      height: 2.6rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .latest-news-card-text {
      font-size: 1rem;
      opacity: 0.7;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      height: 125px;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .latest-news-readnow-text {
      // overflow: hidden;
      // position: relative;
      // display: inline-block;
      // text-decoration: none;
      // width: 110px;
      // height: 41px;
      // color: #FFF;
      // text-align: center;
      // font-family: Roboto;
      // font-size: 16px;
      // font-style: normal;
      // font-weight: 700;
      // line-height: 25px; /* 156.25% */
      // letter-spacing: 0.16px;
      // border-radius: 21px;
      // background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
      // border: 0px; /* Removed duplicate and set to 0px */
      display: flex;
      max-width: 120px;
      flex-direction: column;
      color: #fff;
      text-align: center;
      letter-spacing: 0.16px;
      font: 700 16px/2 Roboto, sans-serif;
      border-radius: 24px;
      background: var(
        --gradient-primary,
        linear-gradient(90deg, #19417a 0%, #0b9444 100%)
      );
      padding: 8px 18px;
      border: none;
    }
  }
}
