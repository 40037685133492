.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'Noto Sans ';
  src: url('./asserts/Fonts/noto-sans.bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('./asserts/Fonts/noto-sans.regular.ttf');
  font-weight: normal;
}

.koulen-regular {
  font-family: "Koulen", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body{
  font-family: 'Noto Sans'!important ;
}

.card-hover{
  transition: all 0.25s ease;
  &:hover{
    transform: rotate(5deg);
    transform: scale(1.02);
  }
  &.card-less-hover{
    &:hover{
      transform: rotate(5deg);
      transform: scale(1.02);
    }
  }
}

.center_title{
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-container{
  margin: 0 auto;
}


  @media (max-width: 575px){
    .text-container{
      max-width: 365px;
  }
  }

  @media (min-width: 576px){
    .text-container{
      max-width: 540px;
      }
    }

  @media (min-width: 768px){
    .text-container{
      max-width: 720px;
      }
    }

  @media (min-width: 992px){
    .text-container{
      max-width: 960px;
      }
    }

  @media (min-width: 1200px){
    .text-container{
      max-width: 1140px;
      }
    }

  @media (min-width: 1400px){
    .text-container{
      max-width: 1410px;
      }
    }

    .validation-text{
      color:red;
    }

    .data_not_fount_title{
      font-family: "Koulen", sans-serif;
      font-weight: 400;
      font-style: normal;
    }