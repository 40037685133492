.runningTextStates {
    margin: 0px 10px 0px 10px;
    cursor: pointer;
    padding: 1rem 1.5rem;
  }
  
  .scrollContainer {
    display: grid;
    height: 100%;
    grid-gap: 0 20px;
    grid-auto-flow: column;
    grid-auto-columns: 50%;
    overflow-x: auto;
  }
  
  .header {
    background-color: rgb(137, 104, 214);
    white-space: nowrap;
    width: 100%;
    color: white;
    display: flex;
    /* overflow-x: hidden; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  @media (max-width: 640px) {
    .runningTextStates {
      padding: 1rem 0rem;
      font-size: 14px;
    }
  }
  
  @media (max-width: 431px) {
    .runningTextStates {
      margin: 0;
      padding: 1rem 0.3rem;
      font-size: 12px;
    }
  }
  
  /* banner CSS */
  
  .bannerContainer {
    position: relative;
    width: 100vw;
  }
  
  .bannerImage {
    width: 100%;
    height: auto;
  }
  .participantTeam {
    color: white;
    font-size: 4rem;
    font-weight: bold;
    position: absolute;
    bottom: 10rem;
    left: 10%;
  }
  /* Adjust banner container for mobile */
  @media screen and (max-width: 767px) {
    .bannerContainer {
      padding: 1rem;
      text-align: center;
    }
  
    .bannerImage {
      height: 250px;
      width: 100%;
      object-fit: fill;
    }
  
    .participantTeam {
      top: 1%;
      left: 13%;
      font-size: 1.5rem;
    }
  }
  
  
  @media only screen and (max-width:600px){
    .participantTeam {
      top: 50%;
      left: 23%;
      font-size: 1.5rem;
  
    }
  
    
  }