.button_title{
    background-color: green;
    color:#fff;
    border-radius: 40px;
    font-size: 1rem;
    border:1px solid green;
    padding:5px 20px;
}

.card-content {
    overflow: auto;
    max-height: 450px; /* Adjust this value as needed */
}

.table-responsive {
    overflow-x: auto;
}


.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: #555;
    color:red;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the tooltip text */
    left: 50%;
    margin-left: -80px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.totol_tip{
    color:red;
    border:1px solid red;
}

.file_img{
    width: 90px;
}

.margin_left{
    margin-left: 30px!important;
}

.profile_update_text{
   
    color: #212121;
    font-family: "Noto Sans";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.card_box_wrapper{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.res_card_heading {
    color: #000;
    font-family: Noto Sans;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
   
}
.alertBoxheader{
    height: 100px;
   }
   .bordeCn{
     border: 2px solid black;
   }
   .nameHeader{
       font-weight: 500;
       font-size: 24px;
       
   }
   .txtAlign{
       margin-top: 20px;
       text-align: right;
   }
   .regHeader{
       text-align: center;
   }
   .round-button {
       background-color: #0a983a;
       color: white;
       border: none;
       border-radius: 50%;
       width: 40px;
       height: 40px;
       /* //display: flex; */
       justify-content: center;
       align-items: center;
       cursor: pointer;
       font-size: 24px;
       transition: background-color 0.3s ease;
       margin-right: 10px;
     }
     
     .round-button:hover {
       background-color: #0056b3;
     }
     
     .round-button i {
       pointer-events: none; /* Prevent the icon from interfering with the button click */
     }
     .round-button-log {
       background-color: #E77D22;
       color: white;
       border: none;
       border-radius: 50%;
       width: 40px;
       height: 40px;
       /* //display: flex; */
       justify-content: center;
       align-items: center;
       cursor: pointer;
       font-size: 24px;
       transition: background-color 0.3s ease;
       margin-right: 10px;
     }
     
     .round-button-log:hover {
       background-color: #0056b3;
     }
     
     .round-button-log i {
       pointer-events:none     ; /* Prevent the icon from interfering with the button click */
     }
     .divgap{
       margin-top: 15px;
     }
     .fieldText{
       font-weight: 500;
       color: #0056b3;
     }
     .organisationDet{
        border: 1px solid rgba(158, 150, 150, 0.697);
        border-radius: 5px;
        padding: 20px;
        box-shadow: #cfcdcd;
     }
     .detHeader{
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 50px;
        margin-top: 10px;
     }
     .formDiv {
      border: 1px solid rgba(173, 169, 169, 0.871); 
      padding: 30px;
      border-radius: 5px;
   }

.formHeader{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
    margin-left: 20px;
    text-align: left;
}
.txtGap{
  margin-bottom:15px;
  font-weight: 600;
  font-size: 16px;
  color: #5d41af
}