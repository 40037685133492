.blue-bg {
  background: #011d46;
  padding-bottom: 80px;
}

.sec-section {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
  }
}

/* Card Styles */
.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.no-underline{
  text-decoration: none;
}

.sec-section-1 {
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.viewMore-btn {
  border: 2px solid rgb(95, 198, 95);
  outline: none;
  padding: 10px 18px 10px 20px;
  border-radius: 25px;
  color: rgb(95, 198, 95);
  font-weight: bold;
  background-color: transparent;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.people-sec {
  overflow: hidden;
  position: relative;
  // display: flex;
  align-items: flex-end !important;
}

.boxcard {
  position: relative;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
}

.card-img img {
  width: 100%;
}

.card-img {
  position: relative;
}

.bottom-text {
  display: flex;
  justify-content: space-between;
}

.text-section {
  position: absolute;
  bottom: 3%;
  left: 25px;
}

.wrap_title {
  white-space: nowrap;
}

.home_gallery_head {
  font-size: 1.5rem;
  font-family: "Noto Sans";
  color: #fff;
  font-weight: 700;
}

.text-section-1 {
  padding: 1.25rem;

  .section-1-heading {
    font-family: Noto Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;
    // letter-spacing: 1px;
    padding-bottom: 0px;
    padding-left: 2px;
  }
}

.text-section-1.extra {
  padding: 20px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.person {
  position: absolute;
  top: 0;
  right: 10px;
}

.tom-img {
  background: url("../../asserts/Home/grid_images/athlete-bg.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 45px 25px 0px 15px;
}

.girl-bg {
  background: url("../../asserts/Home/grid_images/FourthGroup3.webp");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.arrowIcon {
  width: 30px;
  height: 30px;
}

.para {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Noto Sans";
}

.popup-overlay {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 29, 70, 0.8);
  opacity: 1;
}

.popup-container {
  position: relative;
  border-width: 7px;
  border-style: solid;
  border-image: linear-gradient(to right, #82ac8a, #1f9b32) 1;
  height: 650px;
}

.popup-container video {
  height: 100% !important;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px 20px;
  outline: unset;
  border-radius: 10px;
  background: #fff;
  z-index: 999;
  transition: 0.5s ease;
}

.close-button:hover {
  background: rgb(81, 188, 81);
  color: #fff;
}

.link-style {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 790px) {
  .people-sec {
    display: flex;
    align-items: end;
  }

  // uncommented section
  // .girl-bg{
  //   height:330px !important;
  // }
  // **********
}

@media (min-width: 790) {
  // .aboutus{
  //   width: 50%;
  // }
}

@media (min-width: 1100px) {
  .two-card {
    display: flex;
    gap: 10px;

    .aboutus {
      width: 50%;
    }

    .flexsecond-card {
      flex-grow: 1;
    }
  }
}

.view-more {
  color: #d7dbff !important;
}

.card1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  background-color: white;
  border-radius: 36px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 20px 0px 20px;
  // max-width: 600px;
  width: 100%;
}

.card2 {
  background-color: white;
  border-radius: 36px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 25px 30px 25px 30px;
  // max-width: 600px;
  width: 100%;

}

hr {
  border: 0;
  height: 0.5px;
  /* Thickness of the line */
  background-color: #545252;
  /* Line color */
  margin: 20px 0;
  /* Space around the line */
}


/* Card Text Section */
.card1-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 20px;
}

.card1-text h2 {
  margin-bottom: 10px;
}

.card1-text p {
  color: #555;
}

/* Card Image Section */
.card1-image {
  flex-shrink: 0;
}

.card1-image img {
  max-width: 300px;
  border-radius: 10px;
}

.actionBtn {
  width: 150px;
  font-weight: 500;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.tenderBtn {
  width: 150px;
  font-weight: 500;
  border: none;
  padding: 10px;
  border: 2px solid rgb(95, 198, 95);
  color: rgb(95, 198, 95);
  
  border-radius: 5px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 30px;
  justify-content: space-around;
  align-items: center;
  
}

/* Wrapper for controlling the height of the scroll area */
.scrolling-text-wrapper {
  height: 250px; /* Set a fixed height based on your design */
  overflow: hidden; /* Hide content that overflows the wrapper */
  position: relative;
  margin-top: 20px;

}

/* List of items to scroll */
.scrolling-text {
  list-style: none;
  padding: 0;
  margin: 0;
  animation: scroll-one-by-one 18s linear infinite;
  animation-play-state: running; /* Adjust the time for the speed */
}

/* Each list item scrolls one by one */
.scrolling-text li {
  padding: 10px 10px;
  opacity: 0;
  animation: fade-in-out 6s linear infinite;
  color: #932626;
  font-weight: 400;
  font-family: "Koulen", sans-serif;
  list-style: none; /* Remove default bullets */
  position: relative; /* To position the arrow */
}

.scrolling-text li::before {
  content: "➔"; /* Unicode for arrow */
  position: absolute;
  left: -10px; /* Adjust position to align the arrow */
  color: #555; /* Set arrow color */
  font-weight: bold;
}

/* Animation for the entire list to scroll up */
@keyframes scroll-one-by-one {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%); /* Move the entire list up */
  }
}

/* Fade in each list item one by one */
@keyframes fade-in-out {
  0%,
  20% {
    opacity: 1;
  }
  40%,
  60% {
    opacity: 1;
  }
  80%,
  100% {
    opacity: 1;
  }
}


@keyframes scroll-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.scrolling-text-wrapper:hover .scrolling-text {
  animation-play-state: paused;
}


/* Responsive: Image goes below text on smaller screens */
@media (max-width: 720px) {
  .card1 {
    flex-direction: column;
    align-items: center;
  }

  .card1-image {
    margin-top: 20px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    /* Two equal columns */
    gap: 20px;
  }

}

.gridContainer {
  //padding: 6%;
}
.text1-container {
  padding: 0 4% 0 4%;
}

/* Default styles (mobile first) */

/* For small devices (portrait phones, less than 600px) */
@media (max-width: 719px) {

  /* CSS for phones */
  .grid-container {
    display: grid;
    padding: 4%;
    grid-template-columns: 1fr;
    /* Two equal columns */
    gap: 20px;
  }
  .schemePad{
    padding: 4%;
  }

  .pdMob {
    padding: 0 6% 0 6%;
  }

  .text-section-1 {
    padding: 2.25rem;
  }

  .card1-image img {
    max-height: 196px;
    border-radius: 10px;
    margin-top: -100px;
    margin-left: 115px;
  }

  .videopadding {
    padding: 0 6% 0 6%;
  }

  .tenderBtn {
    display: none;
  }

  .tenderBtn-mobile {
    width: 150px;
    font-weight: 500;
    border: none;
    padding: 15px;
    border: 2px solid rgb(40, 102, 189);
    color: rgb(40, 102, 189);
    border-radius: 5px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  .athlete {
    width: 250px;
    margin-left: 100px;
  }
}
.hemant-soren-bg {
  background: url("../../asserts/OrganisationalStructure/sudivyakumarBG.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 45px 25px 0px 15px;
  width: 84rem;

  @media (max-width: 915px) {
    width: 20rem;
    height: 200px;
    padding: 45px 25px 0px 0px;

    h1 {
      font-size: 1.5rem !important;
    }
  }

}
.mithilesh-kumar-bg {
  background: url("../../asserts/OrganisationalStructure/mithileshKUmarBG.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 45px 25px 0px 15px;

  @media (max-width: 915px) {
    width: 100%;
    height: 200px;
    padding: 45px 25px 0px 0px;
    h1 {
      font-size: 1.5rem !important;
    }
  }
}

.manoj-kumar-bg {
  background: url("../../asserts/OrganisationalStructure/manojKumarBG.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 45px 25px 0px 15px;
 
  @media (max-width: 915px) {
    width: 100%;
    height: 200px;
    padding: 45px 25px 0px 0px;
    h1 {
      font-size: 1.5rem !important;
    }
  }
}

.sandip-kumar-bg {
   background: url("../../asserts/OrganisationalStructure/shriSandeepJASBG.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 300px;
  padding: 45px 25px 0px 15px; 

  @media (max-width: 915px) {
    width: 100%;
    height: 200px;
    padding: 45px 25px 0px 0px;
    h1 {
      font-size: 1.5rem !important;
    }
  }
}

@media (max-width: 915px) {
.card1 {
flex-direction: row;
font-size: 10px;
}
}

.orgnaization-str-tile {
  // position: relative;
}

.ribbon-org {
  // font-size: 2rem;
  width: 100px;

}
.lates_img{
  width: 300px!important;
  border-radius: 20px;
  height: 375px;
  background-image: linear-gradient(to bottom, transparent, green);

}


/* For medium devices (landscape phones/tablets, 600px and up) */
@media (min-width: 720px) and (max-width: 899px) {

  /* CSS for tablets in portrait mode */
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    /* Two equal columns */
    gap: 20px;
    padding:4%

  }
  .tenderBtn {
    display: none;
  }
  .tenderBtn-mobile {
    width: 150px;
    font-weight: 500;
    border: none;
    padding: 15px;
    border: 2px solid rgb(40, 102, 189);
    color: rgb(40, 102, 189);
    border-radius: 5px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  .pdMob {
    padding: 0 4% 0 4%;
  }

  .card1-image img {
    max-width: 160px;
    border-radius: 10px;
    margin-top: -125px;
    margin-left: -30px;
  }
}

/* For large devices (tablets, desktops, 900px and up) */
@media (min-width: 900px) and (max-width: 1199px) {

  /* CSS for tablets in landscape mode */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal columns */
    gap: 20px;
  }
  .tenderBtn-mobile {
    display: none;
  }

}

/* For extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  /* CSS for desktops and large screens */
  .grid-container {
    padding:4%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal columns */
    gap: 20px;
  }

  .cardHeight {
    height: 360px;
  }
  .tenderBtn-mobile {
    display: none;
  }

}