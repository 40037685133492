.header-container {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}
.blureffect{
  background-color: #014F37;
  filter: blur(2px);
 

}
.content{
  position: relative;
 z-index:1;
}
.menuHeader{
 // height:26vh;
 // background-image: url('/jharkhand_website/src/asserts/AboutUs/about-bg.png');
  padding-left: 50px;
  position: absolute;
  z-index: 100000;
  //filter: blur(2px);
  //opacity: 0.5;

}
.original{
  height: 18vh;
 // padding-left: 50px;
  position: absolute;
  z-index: 100000;
 // background:  linear-gradient(308deg, #1e8160 0%, #205a4882);
 // opacity: 0.3;
}

// .menuHeader::before {
//   content: '';
//   position: absolute;
//   background-color: #014a342e ;
//   filter: blur(10px);
//   //opacity: 0.5;
//   top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
// }
.header_content{
  padding-top: 25px;
  //filter: blur(-2px);
}
.menu {
  // margin-top: 25%;
  // margin-left: 15px;
  height: 40px;
  cursor: pointer;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
}
.search-header {
  cursor: pointer;
}

.logo {
  // margin-top: 30px;
  height: 55px;
}
.menu:hover {
  background-color: #126c27;
  color: white;
}


.logo-bd-flex {
display: flex;
}
.icon-chevron-right {
  display: flex;
  margin-top: 55px;
  margin-left: 10px;
}
.bd-1 {
 color: white;
}
.search_wrapper{
  display: flex;
  justify-content: end;
}
.search_container{
  width: 130px;
}

.search_container_active{
  width: 200px;

}

.logo_wrapper {
  // background-color: white;
  // border-radius: 50px;
  // padding: 10px 25px;
  // display: flex;
  // justify-content:space-evenly; /* Distribute space between images */
  // align-items: center; /* Center items vertically */

  width: 250px;
  background-color: white;
  border-radius: 50px;
  padding: 10px 25px;
  display: flex;
  gap: 0%;
  margin-left: 150px;
  justify-content: space-evenly;
  align-items: center;



}
.mobile{
  display: none;
}
// .logo_wrapper img{
// margin-right: 30px;
// }

.navHeader{
  background: linear-gradient(180deg, #000F0A 0%, #014F37 );
  opacity:0.5;
}
.header_fixed_container {
  position: fixed;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100000000;
  transition: background-color 0.3s;
}

.header_fixed_container.menu-open {
  z-index: 0;
}
.header_fixed_container.scrolled {
  background: linear-gradient(180deg, #000F0A 0%, #014F37 100%);

  
  top:0;
}
@media (min-width: 720px) and (max-width: 899px){
  .logo_wrapper {
    margin-left: 0px;  
  }

}
@media (max-width: 579px) {
  .icon-chevron-right {
    display: none;
  }
}
@media (max-width: 770px){
  .original{
    display: none;
  }
  .mobile{
    display:block;
  }
  .menuHeader{
     padding-left: 10px;
     position: absolute;
     z-index: 100000;
   
   }
  .logo_wrapper{
    margin-left: 0px;
  }
}
.mobilemenu{
 
    width: 50px;         /* Width of the box */
    height: 50px;        /* Height of the box */
    background-color: white;   /* White background */
    border-radius: 50%; 
    border: none;  /* Makes it a circle */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  /* Optional: Adds a subtle shadow */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;    /* Optional: Text inside the box */
    color: black;         /* Text color */
  
}
.search-phone{
  width: 50px;         /* Width of the box */
  height: 50px;        /* Height of the box */
  background-color: white;   /* White background */
  border-radius: 50%;   /* Makes it a circle */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  /* Optional: Adds a subtle shadow */
  display: flex;
 // justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;    /* Optional: Text inside the box */
  color: black;         /* Text color */

}