.etender-container {
  background-image: url("../../asserts/E-tender/etenderbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 1920px;
  height: 700px;
  display: flex;
  gap: 20px;
  flex-shrink: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.heading-text p {
  font-size: 32px;
  font-weight: 500;
}

// E-TENDER-SECTION

.sortBy-e-tender {
  position: absolute;
  left: 80px;
}
.e-tender-head {
  color: #fff;
  text-align: center;
  font-family: Koulen;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem;
}

.e-tender-table-section {
  width: 100%;
  background-color: #f6f7f9;
  padding: 50px 0px 0px 0px;
}

.e-tender-filter-options {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 90%;
  margin-left: 80px;
}

.e-tender-heading p {
  text-align: center;
}

.etender-input-field {
  display: flex;
  width: 800px;

  input {
    padding: 8px;
    width: 600px;
    border: none;
    margin-left: 40px;
  }
}

.e-tender-table-content {
  padding: 20px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-table {
  padding: 120px;
  // border: 1px solid red;
}

// *****************************************************************8

.E-tender-fopDivContainer {
  position: relative;
  background-color: white;
  display: grid;
  font-family: "Noto Sans";
  font-size: 15px;
  grid-template-columns: 80px 300px 1.5fr 2fr 1.5fr 1fr 70px;
  align-items: center;
  width: 100%;
  padding: 13px;
  margin-top: 20px;
  box-sizing: border-box;
}

.message-container {
  word-wrap: break-word; /* Ensures long words wrap to the next line */
  word-break: break-word; /* Breaks overly long words */
  white-space: normal; /* Allows the text to wrap */
  max-width: 100%; /* Optional: Adjust as per your container size */
}

.title_width{
  width: 250px;
  // border:1px solid red;
}

/* Cut out bottom-left corner */
.E-tender-fopDivContainer::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  /* Adjust size as needed */
  border-right: 20px solid transparent;
  /* Adjust size as needed */
  border-bottom: 20px solid #f6f7f9;
  /* Adjust color as needed */
  border-left: 20px solid #f6f7f9;
  /* Adjust color as needed */
}

/* Cut out top-right corner */
.E-tender-fopDivContainer::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 20px solid #f6f7f9;
  /* Adjust color as needed */
  border-right: 20px solid #f6f7f9;
  /* Adjust color as needed */
  border-bottom: 20px solid transparent;
  /* Adjust size as needed */
  border-left: 20px solid transparent;
  /* Adjust size as needed */
}
.E-tender-fopDivContainer:hover {
  cursor: pointer;
  color: #059e70;
}

.E-tender-fopDivContainer {
  transition: color 0.3s ease-in-out;
}

.E-tender-fopDivContainer.hovered {
  cursor: pointer;
  color: #059e70;
}

// .E-tender-fopDivContainer.hovered img {
//   content: url("../../asserts//E-tender/green_pdf.png");
// }

.E-tender-fopTableHeader {
  background-color: #059e70;
  font-family: "Noto Sans";
  font-size: 16px;
  align-items: center;
  color: #fff;
  display: grid;
  grid-template-columns: 80px 2fr 1.5fr 2fr 1.5fr 1fr 70px; /* Same as the container for consistency */
  padding: 15px 20px;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding in width calculation */
  overflow: hidden;
}

.E-tender-fopTableHeader::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f6f7f9;
  border-left: 15px solid #f6f7f9;
}

.E-tender-fopTableHeader::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 15px solid #f6f7f9;
  border-right: 15px solid #f6f7f9;
  border-bottom: 15px solid transparent;
  border-left: 15px solid transparent;
}

.container2 {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.table2 {
  width: 89vw;
  max-width: 100%;
  overflow-x: auto;
  font-size: 15px;
  border-collapse: collapse;
}

.e-tender-search-field {
  display: flex;
  justify-content: center;

  input {
    width: 390px;
    flex-shrink: 0;
    padding: 8px;
    border: none;
    outline: none;
    padding-left: 30px;
    background-color: transparent;
    background-image: url("../../asserts/Athlete/searchInput.png");
  }

  img {
    background: linear-gradient(to right, white 70%, transparent 70%);
    cursor: pointer;
  }
}

@media screen and (max-width: 870px) {
  .table2 {
    width: 85vw;
  }
}
@media screen and (max-width: 668px) {
  .e-tender-filter-options {
    justify-content: flex-start;
    gap: 10px;
  }
  .sortBy-e-tender {
    position: static;
  }
  .table2 {
    width: 80vw;
  }
  .e-tender-table-content {
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  .e-tender-search-field input {
    width: 200px;
  }
}
