.athlete-profile-statistics {
  background-color: #f6f7f9;
max-width: 1400px;
  .headingSetion {
    .statistics-filters1 {
      padding: 30px 24px 18px 0px;
      display: flex;
      flex-direction: row;
      justify-content: end;
      gap: 10px;
    }
    .win-los-container {
      width: 100%;
      background: #004F37;
      color: white;
      border-radius: 12px;
      padding: 50px 30px 30px 30px;
      h5 {
        font-family: Lato;
        font-size: 30px;
        font-weight: 600;
        line-height: 40px;
        text-align: left;
      }
      .blue-bg {
        background: #059E70;
        border: 1px solid #059E70;
        border-radius: 12px;
        padding: 20px;
        margin: 18px 0px;
      }
      .statistics-matches {
        .heading-st-ma {
          display: flex;
          justify-content: center;
          font-family: Lato;
          font-size: 30px;
          font-weight: 600;
          line-height: 40px;
          text-align: left;
        }
        .line-statistics{
            display: flex;
            justify-content: space-between;


            .statusbar-win-los{
               text-align: center;
                width: 80%;
            }
        }
      }
      .statistics-listing{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding: 20px;
        .listing-box{
            width: 33%;
            border-left : 1px solid #05CF93;
            min-height: 100px;
            color: var(--2222, #05CF93);
            text-align: center;
            margin-top: auto;
            font-size: 30px;
            display: flex;
            justify-content: center;
            p{
                font-size: 70px;
                font-weight: 900;
            }
            span{
                font-size: 20px; 
                  margin: auto 0px auto 0px;
                  color: white;
                  text-align: left;
                
            }

        }
        .statistics-listing > :first-child {
            border: none !important;
        }
      }
    }
  }
  .carrier-high-rank{
    .carrier-high-rank-heading{

    }
  }
}

.tournaments_title{
  color: var(--uu, #05CF93);


font-size: 5rem;
font-style: normal;
font-weight: 900;
line-height: 6.25rem; /* 125% */
}

.tournaments_para{
  color: #FFF;
  font-size: 20px;
font-style: normal;
font-weight: 400;
 /* 133.333% */
}

.card_month{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  height: 100%;
 
  
}
.fullstack_title{
  text-align: left!important;
}

.month_title{
  color: #212121;
font-size: 1.875rem;
font-style: normal;
font-weight: 600;
line-height: 2.5rem; /* 133.333% */
text-transform: uppercase;
text-align: center;

}
.month_list{
  list-style-type: none;
}

.month_number{
  color: #212121;
font-size: 2rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem; /* 90.909% */
text-align: center;
}

.month_para{
  color: #606060;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.1rem; /* 200% */
}

.border_bottom{
  border: 2px solid green;
  margin-top: 5px;
  border-radius: 2px;
}

.raking_table_wrapper{
  --bs-table-bg: red;

}
.ranking_Table_tr{
  background-color: #059E70;
}

.historical_title{
  color: #212121;
font-size: 1.875rem;
font-style: normal;
font-weight: 400;
line-height: 2.5rem; /* 133.333% */
text-transform: capitalize;

}

.char_work{
  color: var(--ggggg, #059E70);
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.25rem; /* 100% */
}
