.achievement-container {
    background-color: #f6f7f9;
}
.achievement-header {
    display: flex;
    justify-content: space-between;
    padding: 40px 10px 10px 5px;

    h2 {
        font-weight: bold;
    }
}
.achievemnt-filters {
    display: flex;
    justify-content: space-around;
}

.athlete-achivmnt-heading {
   
 p {
    margin: 0px;
    text-align: center;
    padding: 15px;
    font-weight: bold;
    font-size: 25px;
    background-color: white;
 }
}


// ************table***************


.achievemnt-table2 {
    width: fit-content;
    font-size: 15px;
  }
  

.achievement-DivContainer {
    position: relative;
    /* Required for absolute positioning of pseudo-elements */
    background-color: white;
    display: grid;
    grid-template-columns: 200px 300px 300px 250px 200px;
    align-items: center;
    max-width: fit-content;
    min-width: fit-content;
    padding: 0px 10px 0px 30px;
    margin-top: 20px;
  }
  
  /* Cut out bottom-left corner */
  .achievement-DivContainer::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    /* Adjust size as needed */
    border-right: 20px solid transparent;
    /* Adjust size as needed */
    border-bottom: 20px solid #f6f7f9;
    /* Adjust color as needed */
    border-left: 20px solid #f6f7f9;
    /* Adjust color as needed */
  }
  
  /* Cut out top-right corner */
  .achievement-DivContainer::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 20px solid #f6f7f9;
    /* Adjust color as needed */
    border-right: 20px solid #f6f7f9;
    /* Adjust color as needed */
    border-bottom: 20px solid transparent;
    /* Adjust size as needed */
    border-left: 20px solid transparent;
    /* Adjust size as needed */
  }
  
  .achievement-TableHeader {
    background-color: #059e70;
    align-items: center;
    color: #fff;
    display: grid;
    max-width: fit-content;
    min-width: fit-content;
    grid-template-columns: 200px 300px 300px 250px 200px ;
    padding: 10px 10px 10px 30px;
    min-width: fit-content;
  }
  
  .achievement-TableHeader::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #f6f7f9;
    border-left: 15px solid #f6f7f9;
  }
  
  .achievement-TableHeader::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 15px solid #f6f7f9;
    border-right: 15px solid #f6f7f9;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
  }

  .raking_cards{
    position: relative;
    background-color:#fff;
    align-items: center;
    color: #fff;
  
    
  text-align: center;
    padding: 10px 10px 10px 30px;
   
  }

  .raking_cards::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #f6f7f9;
    border-left: 15px solid #f6f7f9;
  }

  .raking_cards::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 15px solid #f6f7f9;
    border-right: 15px solid #f6f7f9;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
  }
  .raking_title{
    color:black;
  }
  .text_center_title{
    text-align: center;
  }


@media (max-width: 720px) {
    .achievement-header {
        flex-direction: column;
      padding-left: 20px;
    }
    .achievemnt-filters  {
        flex-direction: column; 
        gap: 10px;
    }
}