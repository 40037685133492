.videoContainer {
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.videoMainSection {
  margin: 4rem 3rem 0 3rem;
}
.videoFlex {
  display: flex;
  overflow: hidden;
  gap: 20px;
}

.videoTag {
  position: absolute;
  left: 0;
  background-color: #7a23d5;
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  bottom: 0;
  border-bottom-left-radius: 10px;
  width: 8rem;
  text-align: center;
  font-weight: bold;
}

.videoImageExtraTag {
  background: #b328d1;
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  right: -8px;
}

.videoCeremonyTag {
  /* border: 1px solid #a2089c;
  color: #a2089c; */
  color: var(--blue-fff, #19417A);
  border-radius: 4px;
  border: 1px solid var(--blue-fff, #19417A);
  
  background: #FFF;
font-family: Lato;
  width: fit-content;
  padding: 0 1rem;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;


}

.videoGradient {
  border-radius: 59px;
  background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
  /* background: linear-gradient(to right, #4c83c5, #5d3f7f); */
  min-height: 804px;
}
.videoGrid {
  display: grid;
  grid-template-columns: 1fr 400px;
}
.hideImage {
  display: block;
}
@media (max-width: 989px) {
  .videoGrid {
    grid-template-columns: 1fr;
  }
  .videoGrid > img {
    display: none;
  }
  .hideImage {
    display: none;
  }
}
