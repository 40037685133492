.banner_heading_nato {
  font-size: 3.5rem;
  font-weight: 700;

  font-family: "Noto Sans";
  // border:1px solid red;
}

.home-banner {
  position: absolute;
  z-index: 99;
  top: 0;
  // min-height: 860px; commented for now for the card z-index issue
  width: 100%;
}

#videoBack {
  width: "100%";
  height: "100%";
  object-fit: "cover";
  position: fixed;
  right: 0;
  bottom: 0;

}

.linear-gradient {
  background-image: linear-gradient(to right, #59b490, #188241) !important;
  height: 10px;
  width: 100%;
}

.banner_para {
  font-size: 1rem;
  font-weight: 700;
  font-family: "Noto Sans";
  line-height: 26px;
}

.home_banner_height {
  height: 85vh;
}

.form_home_banner_text {
  color: #fff;
  font-family: "Noto Sans";
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem;
  /* 114.286% */
}

.form_home_banner_para {
  color: #fff;

  font-family: "Noto Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.625rem;
  /* 130% */
}

.running-bar {
  background-image: linear-gradient(to right, #29674e, #0d4422) !important;
  color: #ffff;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  flex-shrink: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    animation: move 45s linear infinite;

    li {
      display: inline-block;
      padding: 0 10px;
      font-size: 17px;
    }

    li:before {
      content: "\2022";
      margin-right: 5px;
      font-size: 35px;
      vertical-align: middle;
    }
  }
}

.running-bar:hover ul {
  cursor: pointer;
  animation-play-state: paused;
}

.running-bar li::before {
  content: attr(data-items);
  display: inline-block;
  white-space: nowrap;
}

@keyframes move {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.header-btn {
  margin-top: 30px;
}

.banner-header {
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Koulen", sans-serif;
  font-size: 4.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5.125rem;
  /* 109.333% */
}

.downArrow {
  height: 30px;
}

.heading-text {
  font-size: 45px;
  font-weight: 700;
  font-family: "Koulen", sans-serif !important;
}

.banner-video {
  position: relative;
  background-size: cover;
  height: 640px;
  display: flex;
  color: white;
  background-image: url("../../asserts/Home/banner-bg.png");
  background-repeat: no-repeat;
}




.banner-video:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to bottom,
      rgba(255, 0, 0, 0),
      rgb(3, 24, 7));
}

.grid_green_bg {
 // background-image: linear-gradient(141deg, #000f0a 0%, #014f37 100%);
}

.scroll_text {
  color: #fff;

  text-align: center;
  font-family: "Noto Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.header-scroll {
  // padding-top: 7.5rem;
  // padding-bottom: 78px;
  text-align: center;

  p {
    padding-bottom: 0px;
    // font-size: x-large;
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

.bounce-icon {
  animation: bounce 1s infinite;
  height: 30px !important;
  font-size: xx-large !important;
  width: 50px !important;
  color: #fff;
}


@media (min-width: 1200px) {
  .videoplay {
    height: 890px;
  }
}

@media (max-width: 1200px) {
  .banner-video {
    height: 890px;
  }

  .videoplay {
    height: 890px;
  }
}

@media (max-width: 990px) {
  .banner-video {
    height: 1020px;
  }
}

@media (max-width: 690px) {
  .banner-video {
    height: 1180px;
  }
}

@media (max-width: 490px) {
  .banner-video {
    height: 1100px;
  }

  .banner-header {
    font-size: 32px;
  }

  .heading-text {
    font-size: 28px;
  }
}

@media (max-width: 380px) {
  .banner-video {
    height: 1010px;
  }
}

//----------------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------------------------

.homepage {

  background: linear-gradient(0deg, rgba(1, 79, 55, 1) 28%, rgba(2, 38, 27, 1) 54%, rgb(3, 17, 11) 85%);
  // height: 500vh;

}

.image-banner {
  // position: fixed;
  //background-size:contain;
  height: 550px;
  display: flex;
  //grid-template-columns: 430px 2fr;
  color: white;
  background-image: url("../../asserts/Home/banner-bg1.png");
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.video-banner {
  position: fixed;
  top: 0;
  left: 0;
  // background:#ff0;
  z-index: 500;

  // position: relative;
  // height: 500px;
  // display: flex;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center;
  // background-size: cover;
}

.videoplay {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: -1;
  // position: absolute;

}

.headerStyle {
  margin-left: 5% !important;
  margin-top: 5%;
  font-family: Koulen;

  font-size: 4.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5.125rem;
  // text-shadow: 0 4px 4px
}

.header2Style {
  margin-top: -20px;
  margin-right: 10%;
  margin-left: 5%;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
}

.para {
  margin-right: 20%;
  margin-left: 5%;
  font-weight: 600;
  font-size: 2.5rem;
  font-style: normal;
}

.videoBan {
  display: none;
}

.pading {
  margin-top: 250px;
}

.scrollreveal {
 
  color: white;
  text-align: center;
  margin-top: 30px;
}

.contentdiv {
  position: relative;
  //display: flex;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.reveal {
  // position: relative;
  // height: 500px;
  // display: flex;
  //  background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center;
  // background-size: cover;
}

@media (max-width:770px) {
  .headerStyle {
    margin-left: 5% !important;
    margin-top: 30%;
    font-family: Koulen;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    line-height: 2.5rem;
  }

  .header2Style {
    margin-top: -20px;
    margin-right: 10%;
    margin-left: 5%;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 600;
    }

  .image-banner {
    height: 700px;
  }
  .para {
    margin-right: 10%;
    margin-left: 5%;
    font-weight: 600;
    font-size: 1.5rem;
    font-style: normal;
}
}
.hero-banner{
}
/* Container for the banner */
.ban-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

/* Responsive video */
.ban-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire banner without distorting */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

/* Overlay with text */
.ban-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.56); /* Semi-transparent black overlay */
}

/* Text on top of the video */
.ban-text {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  padding: 20px;
  z-index: 1; /* Ensures the text stays above the video */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .ban-text {
    font-size: 2rem; /* Adjust text size for smaller screens */
  }
}

@media (max-width: 480px) {
  .ban-text {
    font-size: 1.5rem;
  }
}
