.head-container-main {
  width: 100%;
  .head-contianaer {
    background: linear-gradient(180deg, #000f0a 0%, #014f37 100%);
    //   background-image: url("../../asserts/AthleteProfile/bgIsolation.png");
    //   min-height: 580px;
    // background-position: -350px 0px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    .background-curve {
      max-width: 1520px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .title {
      color: #ffffff;
      font-size: 40px;
      position: absolute;
      top: 140px;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'Noto Sans ';
      font-weight: 600;
      
    }
    .players-record-body {
      padding: 4%;
      color: #ffffff;
      .players-record {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: start;
        width: 100%;
        .player-deatils {
          display: grid;
          grid-template-columns: 300px 1fr;
          align-items: center;
          width: 100%;
          .details {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .country {
              display: flex;
              align-items: center;
              gap: 10px;
            }

            .each-detail {
              align-items: center;
              display: grid;
              grid-template-columns: 44% 55%;

              .win {
                display: flex;
                align-items: center;
                gap: 30px;
              }
            }
          }
        }
      }

      .player-record-table {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        background: var(--hhhh, #004f37);
        height: 80px;
        align-items: center;
        padding: 0px 20px;
        .player1 {
          text-align: left;
        }

        .vs {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            background: #059e70;
            font-size: 40px;
            width: 120px;
            text-align: center;
            border-radius: 50px;
            height: 60px;
          }
        }

        .player2 {
          text-align: right;
        }
      }

      .seasons-dropdown {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: 30px;
        .dropdown {
          width: 100%;
          //   border: unset;
          //   border-bottom: 1px solid #ffffff80;
        }
      }

      .stats-display-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: 30px;
      }
    }
  }
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

//RangeBar styles

.range-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #059e70;
  border: 1px solid #05cf9380;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;

  .range-bar-wins {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    .bar {
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid #05cf9380;
      border-radius: 20px;
    }
    .win {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .count {
        color: #05cf93;
        font-weight: 700;
        font-size: 30px;
      }
    }
    .lost {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      .count {
        color: #05cf93;
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
}

// status display  container
.stats-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #059e70;
  border: 1px solid #b6d4ff66;
  border-radius: 10px;
  height: 130px;
  .stat-item {
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    flex-grow: 1;
    margin: 0 10px;
    position: relative;

    .stat-value {
      font-size: 40px;
      font-weight: 700;
      color: #05cf93;
    }

    .stat-label {
      font-size: 1em;
      color: #ffffff;
    }
  }

  .stat-item:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 2px;
    background-color: #05cf9366;
  }
}

//Ropund display

.round-display-main {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 20px;
  .team-place {
    display: flex;
    background: #059e70;
    color: #ffffff;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;
    position: relative;
  }
  .team-place::before {
    border-color: #0000 #0000 #014f37 #014f37;
    border-style: solid;
    border-width: 15px;
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    width: 0;
  }
  .team-place::after {
    border-color: #014f37 #014f37 #0000 #0000;
    border-style: solid;
    border-width: 15px;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
  }

  .team-place::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    top: 64px;
    transform: translateX(-50%);
    border-width: 25px;
    border-style: solid;
    border-color: #059e70 transparent transparent transparent;
  }

  .round-final-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .round-final {
      display: grid;
      grid-template-columns: 44% 4% 4% 4% 44%;
      width: 100%;
      gap: 5px;
      align-items: center;
      .score {
        background-color: #05cf93;
        color: #ffffff;
        height: 60px;
      }
      .score-alignment {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .round-right {
        display: flex;
        background: #059e70;
        color: #ffffff;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 40px;
        height: 60px;
        position: relative;
        gap: 20px;
        .country {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
      .round-right::before {
        border-color: #0000 #0000 #014f37 #014f37;
        border-style: solid;
        border-width: 15px;
        bottom: 0;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
      }
      .round-right::after {
        border-color: #014f37 #014f37 #0000 #0000;
        border-style: solid;
        border-width: 15px;
        content: "";
        height: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
      }
      .round-left {
        display: flex;
        background: #059e70;
        color: #ffffff;
        align-items: center;
        justify-content: flex-end;
        padding: 20px 40px;
        height: 60px;
        position: relative;
        gap: 20px;
        .country {
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
      .round-left::before {
        border-color: #0000 #0000 #014f37 #014f37;
        border-style: solid;
        border-width: 15px;
        bottom: 0;
        content: "";
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
      }
      .round-left::after {
        border-color: #014f37 #014f37 #0000 #0000;
        border-style: solid;
        border-width: 15px;
        content: "";
        height: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
      }
    }
  }
}
