.otp-input {
  margin: 0;
  padding: 0;
  width: 40px; /* adjust as needed */
  height: 40px; /* adjust as needed */
  text-align: center;
}
.otp-verify-login > div {
  margin-left: 180px;
}
.otp-verify-login {
  span {
    margin: 0px;
  }
}

.timer-text {
  margin-left: 150px;
  padding: 10px;
  p {
    padding: 5px;
  }
}

.jharkhand_logos_login {
  display: flex;
  gap: 40px;
  position: absolute;
  top: 60px;
  left: 100px;
}
@media (max-width: 1200px) {
  .otp-verify-login > div {
    margin-left: 0px;
    justify-content: center;
  }
  .timer-text {
      margin-left: 0px;
      align-items: center;
  }
}
