 .phoneNumber_otp {
        padding: 10px;
        margin-bottom: 25px;
        margin-left: 150px;
        width: 80%;
        border-radius: 5px;
    }


.otp_section h1 {
    font-weight: 600;
    width: fit-content;
}

.jharkhand_logos_login {
    display: flex;
    gap: 40px;
    position: absolute;
    top: 60px;
    left: 100px;
  }
.otp_section form input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
}
.otp_section form .otp_page_ {
    margin-top: 20px;
}

.otp_page_ {
    margin-top: 20px;
    margin-left: 150px;
}

.otp_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 8rem !important;
}
.otp_section>p {
    font-size: 14px;
    margin-bottom: 60px;
    text-align: center;
}
.otp_section form {
    width: 100%;
    text-align: center;
}

.otp_section form button {
    width: 100%;
    border-radius: 8px;
    padding: 10px;
}
.otp_section form button.submit-btn {
    font-weight: 600;
    color: white;
    border: none;
    background: linear-gradient(45deg, #422da8, #422da8);
}


@media (max-width: 1180px) {
    .otp_section {
        padding: 0 5rem !important;
    }
}
@media (max-width: 960px) {
    .otp_section {
        padding: 0 2rem !important;
    }
}

@media (max-width: 770px) {
    .otp_section {
        padding: 3rem !important;
    }
    .loginRight {
        height: 80% !important;
        border-radius: unset !important;
        align-items: center;
        padding: 0px !important;
    }
    .right_side_logo_img {
        margin: 20px 0;
    }
    .phoneNumber_otp {
        margin-left: 0px !important;
      }
  }

@media (max-width: 375px) {
    .right_side_logo_img, .right_side_bottom {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
        margin: 20px 0;
    }
    .right_side_logo_img img {
        margin: 0 !important;
    }
    .loginRight h1{
        font-size: 25px !important;
    }
    // .loginLeft {
    //     display: none;
    // }
}