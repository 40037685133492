.activity-container {
    background-color: #f6f7f9;
}

.activity-header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.activity_heading{
    color: #212121;
font-family: "Noto Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.css-11yukd5-MuiTabs-indicator {
  
    background-color: #05cf93!important;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
   
 box-shadow: none!important; 
 border-bottom: 1px solid #BFBFBF;
  
}
.css-1izfg5m {
     background-color:transparent!important;
   
}