.comming_bg{
    background-image: linear-gradient(180deg, #000F0A 0%, #014F37 100%);
    height: 100vh;
}

.coming_soon_title{
    color: #FFF;
text-align: center;
font-family: "Noto Sans";
font-size: 2rem;
font-style: normal;
font-weight: 400;
animation-name: text;
animation-duration: 4s;
animation-iteration-count: infinite;

}

.coming_soon_heading{
    color: #FFF;
    text-align: center;
    font-family: "Koulen", sans-serif;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 6.25rem; /* 125% */

}

.comming_soon_container {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  
  .comming_soon_container span {
    text-transform: uppercase;
    display: block; 
  }
  
  .text1 {
    color: white;
    letter-spacing: 8px;
    margin-bottom: 20px;
    font-family: "Noto Sans";
font-size: 2rem;
font-style: normal;
font-weight: 400;
animation-name: text;
animation-duration: 4s;
animation-iteration-count: infinite;
    
    position: relative;
  /*   shorthand animation property: name | duration | iteration count */
  /*   animation: text 4s 1; */
    animation-name: text;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
  
  .text2 {
    color: #FFF;
    text-align: center;
    font-family: "Koulen", sans-serif;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 6.25rem; /* 125% */;
   
  }

/* @keyframes text {
    0% {
      color: white;
      margin-bottom: -40px;
    }
    30% {
      letter-spacing: 25px;
      margin-bottom: -40px;
    }
    85% {
      letter-spacing: 8px;
      margin-bottom: -40px;
    }
    100% {
      margin-bottom: 20px;
    }
  } */

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .pagination li {
    margin: 0 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .pagination li a {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #555;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .pagination li a:hover {
    background-color: #059e70;
    color: white;
  }
  
  .pagination .active a {
    background-color: #059e70;
    color: white;
    border-color: #059e70;
  }
  
  .pagination .prev-button,
  .pagination .next-button {
    font-weight: bold;
  }
  
  .pagination .disabled a {
    cursor: not-allowed;
    color: #ccc;
    border-color: #eee;
  }
  