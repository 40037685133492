.leftRegistration-stepper {
    background-color: transparent;
}

.MuiStepIcon-text {
    color: white;
}

.MuiStepLabel-active.MuiStepLabel-labelContainer {
    color: none;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    background-color: transparent !important;
}

.MuiStepLabel-iconContainer {

    .MuiStepIcon-root.MuiStepIcon-completed {
        content: "fasdfasd" !important;
        color: transparent !important;
        background-image: url("../../asserts/Registration/tick.png");
        background-size: 21px;
    }

}



.MuiStepIcon-root.MuiStepIcon-active {
    background-color: #50cd89 !important;
    padding: 1px !important;
    border-radius: 2px !important;
}

.MuiPaper-root {
    background-color: transparent !important;
    padding: 0px !important;
    margin: 0px !important
}

.MuiStepIcon-root {
    border: 1px solid #ada0dc;
    background-color: #5b42b9 ;
}

.MuiSvgIcon-root circle {
    /* Hide the circle */
    display: none !important;
}

.leftRegistration-stepper{
    background-color: transparent;
}
.MuiStepIcon-text{
    color: white !important;
}
.MuiStepLabel-label {
  color: white !important;
}
.MuiStepLabel-active.MuiStepLabel-labelContainer {
    color: none;
}


.MuiStepIcon-root.MuiStepIcon-completed {
    color: #50cd89 !important; 
}
.MuiStepIcon-root.MuiStepIcon-active {
   color: #50cd89 !important;
   border-color: #50cd89 !important;
}
