.rti-container {
  background-image: url("../../asserts/RTI/banner.png");
  min-height: 580px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}
.common-heading-rti {
  color: #fff;
  text-align: center;
  font-family: Koulen;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem;
}
.RTI-contents {
  padding: 5%;
}
.RTI-details {
  margin-top: 10px;
}
.text-bold {
  font-weight: 600 !important;
  font-size: 45px !important;
}
.accordion-gap {
  margin-bottom: 40px;
}
.accordion-wrapper {
  border-left: 8px solid #059e70;
}
.accordion-heading {
  font-weight: bold;
  padding: 10px;
}

.accordion-wrapper:last-child .accordion-gap {
  display: none;
}

.Rti-li:hover {
  // color: #416699;
  cursor: pointer;
  // font-weight: bold;
}

.RTI-heading {
  max-width: 100%;
  color: #000;
  font-family: "Noto Sans";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  h2 {
    font-weight: bold;
    font-size: 30px;
  }
  p {
    text-align: justify;
    font-size: 15px;
  }
}
