.bottom-footer-main {
    
    color: white;
    background: #020202;
    
    padding: 10px 50px;
    padding-top: 26px;

}
.bottom-footer-right>p{
    
    margin-right: 15px;
    
}
.bottom-footer-right{
    display: flex;
}

@media (min-width: 775px){
    .bottom-footer-main{
        display: flex;
        align-items: center;
    justify-content: space-between;
    }
}