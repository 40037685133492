.blue-bg {
  background: #011d46;
  padding-bottom: 80px;
}

.sec-section {
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.sec-section-1 {
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.viewMore-btn {
  border: 2px solid rgb(95, 198, 95);
  outline: none;
  padding: 10px 18px 10px 20px;
  border-radius: 25px;
  color: rgb(95, 198, 95);
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
}

.people-sec {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  align-items: flex-end;
}

.boxcard {
  position: relative;
  overflow: hidden;
  padding: 0 15px;
}

.card-img img {
  width: 100%;
}

.card-img {
  position: relative;
}

.bottom-text {
  display: flex;
  justify-content: space-between;
}

.text-section {
  position: absolute;
  bottom: 3%;
  left: 25px;
}

.wrap_title {
  white-space: nowrap;
}

.home_gallery_head {
  font-size: 1.5rem;
  font-family: "Noto Sans";
  color: #fff;
  font-weight: 700;
}

.text-section-1 {
  padding: 1.25rem;
  .section-1-heading {
    font-family: Noto Sans;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: left;
    padding-bottom: 0px;
    padding-left: 2px;
  }
}



.text-section-1.extra {
  padding: 20px;
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.person {
  position: absolute;
  top: 0;
  right: 10px;
}

.tom-img {
  background: url("../../asserts/Home/grid_images/athlete-bg.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 45px 25px 0px 15px;
}

.girl-bg {
  background: url("../../asserts/Home/grid_images/FourthGroup3.webp");
  background-repeat: no-repeat;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.arrowIcon {
  width: 30px;
  height: 30px;
}

.para {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Noto Sans";
}

.popup-overlay {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(1, 29, 70, 0.8);
  opacity: 1;
}

.popup-container {
  position: relative;
  border-width: 7px;
  border-style: solid;
  border-image: linear-gradient(to right, #82ac8a, #1f9b32) 1;
  height: 650px;
}

.popup-container video {
  height: 100% !important;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px 20px;
  outline: unset;
  border-radius: 10px;
  background: #fff;
  z-index: 999;
  transition: 0.5s ease;
}

.close-button:hover {
  background: rgb(81, 188, 81);
  color: #fff;
}

.link-style {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 790px) {
  .people-sec {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    align-items: flex-end;
  }
}

@media (min-width: 1100px) {
  .two-card {
    display: flex;
    gap: 10px;
    .aboutus {
      width: 50%;
    }
    .flexsecond-card {
      flex-grow: 1;
    }
  }
}

.view-more {
  color: #d7dbff !important;
}
