.RegisterWithAadhar-container,
.RegisterWithAadhar-otp-container {
  max-width: 50%;
 // height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1rem;
  padding-top: 10%;
}

.input-label-aadhar div img {
  margin-left: 25%;
}

.input-label-aadhar {
  .label::before {
    content: "*";
    color: red;
    float: right;
    margin-left: 3px;
  }
}
.boldtext{
  font-weight: 600;
}
.modalBox{
  height: 250px;
  box-shadow: #dbdaddb2;
}
.modalText{
  margin-left: 20px;
  margin-top: 50px;
  font-weight: 600;
}
.buttonDiv{
  margin-top:50px;
  margin-left: 20px;
  text-align: right;
  margin-right: 20px;
}
.noBtn{
background-color: #fff;
color: #422da8;
margin-right: 20px;
width: 70px;
}
.noBtn:hover{
  color: #efefef;
  //background-color: #422da8;
  background-color: #a52424;
  border-color: #a52424;
}
.yesBtn{
  color: #efefef;
  background-color: #422da8;
  border-color:  #422da8;
  width: 70px;
}
.yesBtn:hover{
  color: #fff;
  background-color: #1c7032;
  border-color: #1c7032;
}
.otp-label::before {
  content: "*";
  color: red;
  float: right;
  margin-left: 3px;
}

// .input-label-aadhar div input {
//   margin-left: 25%;
//   width: 48%;
//   border: none;
//   background-color: whitesmoke!important;
//   padding: 5px;
//   border:1px solid red;
// }

.adharInput_title:focus {
  border-color: #007bff;
  /* Border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Box shadow on focus */
}

.register_btn {
  border-radius: 0.375rem;
  background: #422da8;
  border: 1px solid #422da8;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem;
  /* 130% */
}
.register_btn:hover {
  background: #321d9b;
}

.register_btn:disabled {
  background: #422da8;
  cursor: not-allowed; 
  opacity: 0.6; 
}

.Continue_btn {
  background: #422da8;
  border-radius: 0.375rem;
  color: #fff;
  font-family: "Noto Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; /* 130% */
  padding: 8px 15px;
  border: 1px solid #422da8;
}

.thumb-image-container {
  max-width: 100%;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.otp-input-reg {
  margin-left: 25%;
}

.otp-image {
  margin-left: 26%;
}

.p-tag-otp {
  text-align: center;
  margin-top: 10px;
}

.did-not-get-code-ptag {
  text-align: center;
  margin-top: 15px;
}

.resend-btn {
  text-decoration: underline;
  cursor: pointer;
}

.input-label-aadhar {
  .label::before {
    content: "*";
    color: red;
    float: right;
    margin-left: 3px;
  }
}

.otp-label::before {
  content: "*";
  color: red;
  float: right;
  margin-left: 3px;
}

.note-bold {
  color: red;
  max-width: fit-content;
}
.note-bold::before {
  content: "*";
  float: right;
  color:red;
  margin-left: 5px;
  font-weight: bold;
}
@media (max-width: 880px) {
  .RegisterWithAadhar-container {
    align-items: center;
 
    //height: 100vh;
  }

  .thumb-image-container img {
    margin-top: 100px;
  }

  .otp-input-reg {
    flex-wrap: wrap !important;
    margin-left: 10px;
  }

  .otp-image {
    margin-left: 5%;
  }

  .otp-input-reg input {
    width: calc(33.33% - 20px) !important;
  }
}

@media (max-width: 720px) {
  .RegisterWithAadhar-container {
    //height: 100vh;
    max-width: 70%;
    
  }
  .size{
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  .RegisterWithAadhar-container p {
   // margin-left: 15% !important;
  }

  .input-label-aadhar div img {
    margin-left: 15% !important;
  }

  .enter-aadhar-number input {
    margin-left: 15% !important;
  }

  .input-label-aadhar div input {
    //margin-left: 15% !important;
    //width: 75%;
  }

  .proceed-btn-registration button {
    //margin-left: 15%;
    //width: 75%;
  }

  .thumb-image-container img {
    //margin-left: 45%;
    margin-top: 50px;
  }
}
