.atom-container {
    max-width: 90%;
    margin-left: 55px;
    padding-bottom: 4rem;
    padding-top: 6rem;

}

.atom-header {
    margin-top: 80px;
    font-size: 20px;
    font-weight: 600;
}

.stretchable-select {
    width: auto;
    min-width: 200px;
    
  }
  

.select-option-atom {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    padding-top: 20px;
}


.inner-atom {
    border: 2px dashed grey;
    border-radius: 10px;
    p {
        padding: 30px;
        padding-bottom: 20px;
        font-weight: bold;
        width: 220px;
        text-align: center;
    }
}

.inner-atom:hover {
    border: 2px dashed #c5bde9;
    background-color: #f1eeff;
    cursor: pointer;
}
.selected-option {
    border: 2px dashed #c5bde9;
    background-color: #f1eeff;
  }

.selectTag-atom {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    padding-top: 40px;
}
.atom {
    max-width: 30%;
}
.icon {
    width: 20px;
}
.other-option, .email-input {
    margin-top: 10px;
}

.inputField-atom { 
    padding-top: 50px;
    display: flex;
}
.input-atom {
    width: 800px;
}
.modal-label {
    margin-bottom: 5px;
}

@media (max-width: 1260px) {
    .select-option-atom {
        flex-direction: column;
        max-width: 50%;
        gap: 10px;
     }
}


@media (max-width: 760px) {
    .select-option-atom {
        flex-direction: column;
        max-width: 80%;
        gap: 10px;
     }
     .selectTag-atom{
        flex-direction: column;
        gap:25px;
     }

     .atom {
        max-width: 90%;
    }
    .stretchable-select {
       
        max-width: 235px; /* Optional minimum width */
      }
      
}




 