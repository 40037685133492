.formDiv {
    border: 1px solid rgba(173, 169, 169, 0.871); 
    padding: 30px;
    border-radius: 5px;
   }

.formHeader{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
}
.steptwoheader{
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
}

.textStyle{
    color: black;
}
.txtGap{
    margin-bottom:15px;
    font-weight: 600;
    font-size: 16px;
    color: #5d41af
}
.labels{
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 10px;
    }
.note{
    font-size: 12px;
}
.noteformat{
    font-size: 12px;
    color: rgb(100, 99, 99);
}
.steptwolabel{
    
    font-weight:500;
    margin-bottom:10px;
}
.labelsteptwo{
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 15px;
    color: #0056b3;
}