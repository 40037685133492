.calender-container {
  background-image: url("../../asserts/Calender/calender-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
}

.calender-body {
  padding-top: 4%;
  //padding: 100px;
}
// .MuiDateRangeCalendar-root:first-child {
//   display: none;
// }

// .MuiDateRangeCalendar-root{
//   display: none!important;
// }

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #059e70!important;
  font-weight: 500;
}


.calender-dflex {

  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: dense;
}

.calender-month {
  .MuiMonthCalendar-root {
    .MuiPickersMonth-root {
      .Mui-selected {
        background-color: #059e70 !important;
        border-radius: 6px !important;
      
      }
    }
  }
}

.MuiPickersToolbar-root,
.MuiDialogActions-root {
  display: none !important;
}

.css-dplwbx-MuiPickersCalendarHeader-label {
  color: rgba(125, 141, 166, 1);
}

.css-bw88rr-MuiPickersMonth-monthButton.Mui-selected,
.css-1a4q4r2-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  background-color: #059e70 !important;
}

.css-grqin-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  background-color: #059e70 !important;
}

.featured-event {
  background-image: linear-gradient(
    to bottom,
    rgba(170, 241, 255, 1),
    rgba(221, 249, 255, 1)
  );
}

.Previous-event {
  background-image: linear-gradient(
    to bottom,
    rgba(191, 237, 132, 1),
    rgba(223, 254, 183, 1)
  );
}

.Live-event {
  background-image: linear-gradient(
    to bottom,
    rgba(174, 207, 255, 1),
    rgba(219, 234, 255, 1)
  );
}

.Upcoming-event {
  background-image: linear-gradient(
    to bottom,
    rgba(216, 245, 231, 1),
    rgba(233, 253, 251, 1)
  );
}


.events-btn-calender {
  display: flex;
  flex-direction: column;
  gap: 10px;
  div {
    padding: 15px;
    max-width: 454px;
    max-height: 80px;
    min-width: 250px;
    border-radius: 10px;
    text-align: center;
   cursor: pointer;
  }
}

.calender-event-heading {
  color: #000;
  font-family: "Noto Sans";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.d-flex-calender-content p {
  margin: 0;
  /* Remove default margin to avoid extra spacing */
  white-space: nowrap;
  /* Prevent text from wrapping */
}

.calender-event-childs {
  max-width: 395px;
  position: relative;
  background-image: url("../../asserts/Calender/bg-1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  border-radius: 16px;
  max-width: 100%;
}

.calender-event-childs-2 {
  max-width: 395px;
  position: relative;
  background-image: url("../../asserts/Calender/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
}


.calender-event-childs-3 {
  position: relative;
  background-image: url("../../asserts/Calender/bg3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  border-radius: 20px;
  max-width: 100%;
}

.calender-event-childs p,
.calender-event-childs-2 p,
.calender-event-childs-3 p {
  margin: 0;
}

.location-img {
  width: 200px;
}

.d-flex-calender {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.calender-event-details {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-flow: dense;
  div {
    cursor: pointer;
  }
}

.calender-event-childs img.bottom-right,
.calender-event-childs-2 img.bottom-right,
.calender-event-childs-3 img.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.calender-event-details div {
  margin-bottom: 5px;
}

.d-flex-calender-content {
  padding-left: 10px;
}

.d-flex-calender-content p {
  color: #000;
  font-family: "Noto Sans";
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.feb-2023 {
  font-weight: bold;
  font-size: 1.5rem;
  padding: 3rem 0rem 1.5rem 0rem;
}

//table section

.calender-table-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.calender-section-1 {
  border-radius: 15px;
  background-color: #f6f7f9;
  margin-bottom: 40px;
}

.wrestling-row {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.flex-1 {
  display: flex;
}

.j-k-wrestling {
  font-size: 15px;
}

.container-calender {
  position: relative;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #E5E5E5;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.section-buys-ticket {
  padding: 0px;
}
.ticket-wrapper {
  position: absolute;

  top: 0;

  left: 0;
}

.left-section {
  position: relative;
  /* Set left section as the positioning context */
}

.ticket-icon {
  position: absolute;
  top: 18px;
  left: 15px;
}

.ticket-text {
  position: absolute;
  top: 20px;
  left: 48px;
}

.ticket-text p {
  color: white;
  font-weight: bold;
  word-spacing: 2px;
}

.ticket-section-text p {
  color: #059e70;
  font-weight: bold;
  margin-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}
.flex-2 img {
  padding-top: 8px;
}

.sf-s {
  margin-left: 4px;
  padding-top: 20px;
  color: grey;
}
.common-heading {
  font-size: 80px !important;
  line-height: 100px;
}

@media (max-width: 1165px) {
  .calender-body {
   // padding: 50px;
   padding-top:4%
  }
  .calender-dflex {
    margin-bottom: 30px;
    margin: 3%;
  }

  .calender-table-section {
    position: relative;
    margin-left: 30px;
    margin-bottom: 10px;
  }

  .options-calender {
    display: none;
  }
  .arrowKey-calender {
    position: absolute;
    right: 0px;
  }

  .favorite-share-section {
    position: absolute;
    right: 0px;
    margin-right: 40px;
  }

  .sf-s {
    display: none;
  }
  .wrestling-row {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width:480px){
    .calender-event-details {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
}

// @media (max-width: 980px) {
//   .calenders-default-container {
//     align-items: center;
//   }
//   .events-btn-calender div {
//     max-width: 100%;
//   }
//   .calender-table-section {
//     position: relative;
//     margin-left: 30px;
//     margin-bottom: 10px;
//   }

//   .calender-event-details {
//     margin-left: 30px;
//   }

//   .d-flex-calender-content {
//     max-width: 100%;
//   }
//   .options-calender {
//     display: none;
//   }
//   .arrowKey-calender {
//     position: absolute;
//     right: 0px;
//   }

//   .favorite-share-section {
//     position: absolute;
//     right: 0px;
//     margin-right: 40px;
//   }

//   .sf-s {
//     display: none;
//   }

//   .calender-event-childs {
//     max-width: 150%;
//     min-width: 150%;
//     align-items: center;
//     margin: auto;
//   }

//   .calender-section-1 {
//     width: 100%;
//     margin: 0px;
//   }

//   .calender-event-details {
//     align-items: center;
//     max-width: 45%;
//     min-width: 300px;
//     flex-direction: column;
//   }
//   .calender-event-details > div {
//     max-width: 150%;
//   }
//   .calender-dflex {
//     flex-direction: column;
//     margin-left: 30px;
//   }
//   .d-flex-calender {
//     flex-direction: column;
//     max-width: 100%;
//   }
//   .wrestling-row {
//     flex-direction: column;
//     gap: 20px;
//   }
//   .container-calender {
//     flex-direction: column;
//   }
// }
