.news-details-banner {
  background-image: url("../../asserts/NewsDetails/newsDetails-bg.png");
  min-height: 580px;
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
   justify-content: center;
   align-items: center;
}

.news-details-heading {
  color: #fff;

  text-align: center;
  font-family: "Koulen", sans-serif;
  font-size: 5rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem;
}

.heading {
  h3 {
    font-weight: bold;
    font-size: 25px;
    margin-top: 40px;
  }
}

// .img-container {
//   width: 400px; /* Set desired width */
//   height: 150px; /* Set desired height */
//   overflow: hidden; /* Hide any overflow */
// }

.img-container {
  img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
  }
}

.card-date-news-details {
  color: grey;
}

.news-details-container {
  padding-top: 5px;
  width: 96%;
  max-width: 1800px;
  margin: auto;
  margin-bottom: 50px;

  // background-color: ;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  .left-section-News {
    background: #f8f8f8;
    padding: 15px;
    width: 100%;
   
    .news-room-image {
      background: var(--bbbb, #2e9f4e);
      min-height: 100px;
      min-width: 300px;
      border-bottom-left-radius: 20px;
      color: #ffffff;
    
      p{
        font-family: Lato;
        font-size: 18px;
        font-weight: 400;
        line-height: 42px;
        text-align: left;
        width: auto;
        text-align: center;
        }
      h1 {
        font-family: Lato;
        font-size: 45px;
        font-weight: 700;
        text-align: center;
    
        line-height: 42px;
        padding: 5px;
        padding-bottom: 50px;
      
      }
    }
    .news-list-container{
        .news-list-card{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid gray;
            padding: 10px;
        }
        .card-text-container{
          margin-left: 15px;
            .button-news{
            border: 2px solid rgb(32, 32, 65);
            border-radius: 5px;
            max-width: min-content;
            color: rgb(32, 32, 65);
            padding: 0px 10px 0px 10px;
                
            }
            // max-width: 300px;
        }
    }
  }
  .right-section-News {
    flex-grow: 1;
    
    margin-top: 40px;
  }
}

.right-news-detail-container {
  margin: 20px 0px 10px 30px;
  max-width: 65%;

  span {
    display: inline-block;
    border: 1px solid rgb(84, 199, 100);
    padding: 5px 10px 5px 10px;
    color: rgb(84, 199, 100);
    border-radius: 5px;
    margin-bottom: 30px;
  }
  h3 {
    font-weight: bold;
    
  }

  .date {
    margin-top: 40px;
    margin-bottom: 40px;
    color: grey;
  }
}

.article-details {
  display: flex;
  gap: 10px;
  max-width: 60%;
  align-items: center; 
  border-radius: 5px;
  border: 1px solid rgb(48, 48, 75);

  .article-left {
    border: 1px solid grey;
    padding: 10px;
    background-color: #7c7980;
    color: white;
    border-bottom-right-radius: 5px;
  }
}

@media (max-width: 775px) {
  .news-details-container {
    flex-direction: column-reverse;
  }
}
