.previous_btn {
    background-color: #e6e0ff;
    color: #102c56;
    border: 1px solid #e6e0ff;
    border-radius: 0.375rem;
    font-family: "Noto Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem; /* 130% */
    padding:8px 15px;
    margin-right: 1rem;
  }

  .previous_btn:disabled {
    background-color: #d9d6e7;
    cursor:not-allowed; 
  }
  @media (max-width: 720px){
    .marginL{
      margin-left: -17px;
    }
    .save-btn{
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left:0px;
      margin-right: 10px;
    }
  }