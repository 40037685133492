.personal-details-main-container{
    height:95vh;
    width: 100%;
    background-color: white;
    margin-left: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
    font-weight: 500;
}
.personal-details-header {
  padding-top: 50px;
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 1rem;
}
.personal-details-address-header {
  font-weight: 600;
  font-size: 18px;
  padding-top: 10px;
    margin-top: 20px;
  padding-bottom: 1rem;
}
.personal-details-basic-information {
  display: grid;
  gap: 30px;
    margin-top: 20px;
  grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
}
.personal-details-basic-information div {
  display: flex;
  flex-direction: column;
  label {
    padding-bottom: 10px;
  }
  input {
    border: none;
    padding: 10px;
    background-color: #f8f9fa;
  }
  img {
    margin-bottom: 10px;
  }
}
.photo-box {
  width: 160px;
  height: 212px;
  border: 2px dotted #ccc;
  position: relative;
  background-color: #fff;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-box img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.upload-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0 0 8px 8px;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s;
}

.upload-label:hover {
  background-color: #0056b3;
}

.upload-button {
  display: none;
}
.contentbox{
  margin-left: 10px;
  max-width: fit-content;
  padding: 1rem 0rem 1rem 0rem;
}
.formCNT{
    width: 85%;
    margin-left: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 5px rgba(213, 215, 216, 0.21);
}
.formSlt{
    width: 85%;
}
.paswrdShw{
  height: 37.6px;
}

.reset-password-btn {
  margin: 1rem 0rem 1rem 0rem;
  background-color: #422da8;
}

.reset-password-btn:hover {
  background-color: #2e1d82;
}

// .personal-details-basic-information > div:nth-child(1) {
//     grid-row-start: 1;
//     grid-row-end: 2;
//     grid-column-start: 1;
//     grid-column-end: 2;
// }

// .personal-details-basic-information > div:nth-child(5) {
//     grid-row-start: 2;
//     grid-row-end: 3;
//     grid-column-start: 1;
//     grid-column-end: 2;
// }

.personal-details-basic-information > div:nth-child(6) {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
}

// .personal-details-basic-information > div:nth-child(8) {

//     grid-column-start: 1;
//     grid-column-end: 3;
// }

.personal-details-basic-information > div:nth-child(4) {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 4;
  grid-column-end: 5;
}

.personal-details-email-password {
  display: grid;
  padding-top: 20px;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 90%;
  label {
    padding-bottom: 10px;
  }
  input {
    border: none;
    padding: 10px;
    background-color: #f8f9fa;
  }
  img {
    padding-bottom: 10px;
  }
}

.password-container {
  display: flex;
  display: grid;
  padding-top: 20px;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 90%;
}

.personal-details-email-password2 {
  display: grid;
  padding-top: 20px;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 90%;
  label {
    padding-bottom: 10px;
  }
  input {
    border: none;
    padding: 10px;
    background-color: #f8f9fa;
  }
  img {
    padding-bottom: 10px;
  }
}

.personal-details-email-password2 {
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
  }
}

.email-password-1 {
  max-width: 800px;
}

.personal-details-email-password {
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
  }
}

.personal-details-address {
  display: grid;
  gap: 20px;
  width: 90%;
  grid-template-columns: repeat(3, 1fr);

  label {
    padding-bottom: 10px;
  }
  input {
    border: none;
    padding: 10px;
    background-color: #f8f9fa;
  }
}

.personal-details-address div {
  display: flex;
  flex-direction: column;
}

.bi-arrow-up-short {
  font-size: 1.5rem;
}
.upload-field {
    display: flex;
    margin-top:20px;
    //justify-content: center;
   //margin-left: 5px;

}
.pad-button {
  margin-top: 20px;
}
.upload-box {
  width: 160px;
  height: 160px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.upload-box img {
  max-width: 100%;
  max-height: 100%;
  display: none;
}
.text-upload{
  text-align: center;
  margin-left: 10px;
}
.marginpd{
  margin-left: 10px;
}
.upload-btn-pd {
  //background-color: #efefef;
  background-color:#422da8;
  max-width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: #efefef;
  //color: rgb(95, 93, 93);
}
.upload-btn-pd:disabled {
  background-color: #efefef;
  color: rgb(95, 93, 93);
  //color: #efefef;
  cursor: not-allowed;
}

.uploadbutton{
 width: 30%;
 margin-left: 2px;
}
.gapping{
  margin-left:3%
}

@media (max-width: 792px) {
  .personal-details-basic-information {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .personal-details-basic-information > div {
    display: flex;
    flex-direction: column-reverse;
  }
  .personal-details-email-password {
    grid-template-columns: repeat(1, 1fr);
  }
  .personal-details-address {
    // display: grid;
    grid-template-columns: 1fr; /* or any desired column sizing */
    grid-auto-rows: auto;
        margin-bottom: 20px;
    // grid-template-columns: repeat(1, 1fr);
  }
  .personal-details-basic-information {
    // grid-template-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    // flex-direction: column;
  }
  .personal-details-basic-information > div:nth-child(6) {
    grid-row-start: auto;
    grid-row-end: auto;
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .personal-details-basic-information > div:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .password-container {
    // display: grid;
    grid-template-columns: 1fr; /* or any desired column sizing */
    grid-auto-rows: auto;
        margin-bottom: 20px;
        gap:10px;
    // grid-template-columns: repeat(1, 1fr);
  }
}
