.register-as-container {
  max-width: 100%;
  margin-left: 40px;
  margin-bottom: 80px;
  padding-top: 80px;
}

.registeras-header {
  margin-top: 100px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
}

.register-as-header-container {
  margin-top: 20px;
}

.register-as-red {
  color: red;
  margin-left: 10px;
  margin-right: 30px;
  text-align: justify;
  padding: 1rem 0px 2rem 0px;
}

.select-option-registerAs {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.athlete-inner-registerAs {
  border: 2px dashed #a699e0;
  padding: 20%;
  width: 140%;
  border-radius: 5px;
  margin-left: 10px;
  span {
    padding: 0rem 0rem 0rem 1rem;
  }
} 

.athlete-inner-registerAs:hover {
  border: 2px dashed #c5bde9;
  background-color: #f1eeff;
  cursor: pointer;
}

.official-inner-registerAs {
  border: 2px dashed #c5bde9;
  padding: 18%;
  width: 140%;
  border-radius: 5px;
  span {
    padding: 0rem 0rem 0rem 1rem;
  }
}
.technical-official-inner-registerAs {
  border: 2px dashed #c5bde9;
  padding: 17%;
  width: 140%;
  span {
    padding: 0rem 0rem 0rem 1rem;
  }
}
.technical-official-inner-registerAs:hover {
  border: 2px dashed #c5bde9;
  background-color: #f1eeff;
  cursor: pointer;
}

.official-inner-registerAs:hover {
  border: 2px dashed #c5bde9;
  background-color: #f1eeff;
  cursor: pointer;
}

.other-user-type-register-as {
  margin-left: 50px;
  padding: 30px 0px 10px 0px;
}

.register-as {
  margin-left: 50px;
  width: 70%;
}

.athlete-inner-registerAs-active,
.official-inner-registerAs-active,
.technical-official-inner-registerAs-active {
  border: 2px dashed #c5bde9;
  background-color: #f1eeff;
}

@media (max-width: 1260px) {
  .select-option-registerAs {
    flex-direction: column;
  }
  .athlete-inner-registerAs,
  .official-inner-registerAs,
  .technical-official-inner-registerAs {
    padding: 4%;
    width: 50%;
    margin-left: 30px;
    margin-bottom: 10px;
  }
}

@media (max-width: 800px) {
  .athlete-inner-registerAs,
  .official-inner-registerAs,
  .technical-official-inner-registerAs {
    width: 90%;
  }

  .register-as {
    width: 90%;
  }
  .register-as-container {
    margin-left: 20px;
  }
}
