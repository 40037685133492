.loginRight {
    // background: url("../../asserts/login/bg_cut_right.png") no-repeat center;
    // height: 100vh;
    // background-size: cover;
    // border-top-left-radius: 80px;
    // border-bottom-left-radius: 80px;
    // background-color: #6a3ec6;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // padding: 10px;

}

// .loginRight::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     right: 720px;
//     width: 50px; /* Width of the cutout */
//     height: 100%; /* Height of the cutout */
//     background-color: transparent !important; /* Make the background transparent */
//     border-top-right-radius: 200px; /* Apply border-radius to create a circle */
//     border-bottom-right-radius: 200px; /* Apply border-radius to create a circle */
//     overflow: hidden; /* Hide overflow outside the circle */
// }



.loginRight h1 {
    font-size: 30px;
    font-weight: bold;
}

.right_side_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right_side_bottom .arrow_container {
    display: flex;
    align-items: center;
}

.right_side_bottom p {
    font-size: 10px;
    max-width: 60%;
}

.right_side_logo_img {
    display: flex;
    align-items: center;
}

.arrow_container>svg {
    width: 3.5em;
    height: 2.5em;
}


@media (max-width: 1000px) {
    .loginRight h1 {
        font-size: 30px;
    }

    .right_side_logo_img img {
        height: 120px;
        width: auto;
    }
}