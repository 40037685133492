.marquee {
  --gap: 0rem;
  position: relative;
  display: flex;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  gap: var(--gap);
  background-image: linear-gradient(to right, #29674e, #0d4422);
  color: white;
  padding: 8px;
  font-size: 12px;
  font-family: "Noto Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 11px;
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  gap: var(--gap);
  min-width: 100%;
  align-items: center;
  justify-content: center;
}

.marquee_title_label{
  color:#ffff;
  text-decoration: none;
}

.marquee__content li {
  margin-right: 25px;
}

.marque-heading {
  color: #05cf93 !important;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.enable-animation .marquee__content {
  animation: scroll 40s linear infinite;
  margin: 0px;
  padding: 0;
  text-align: center;
}

.enable-animation:hover .marquee__content {
  animation-play-state: paused;
  cursor: pointer;
}
.dot {
  color: white;
  margin: 0px 10px 0px 7px;
}

.link {
  text-decoration: none;
  color: inherit;
}
