.Photo-gallery-container {
  .bgcontainer {
    width: 100%;
    height: 100vh;
    background: var(--33, linear-gradient(180deg, #000f0a 0%, #014f37 100%));
  }

  // .data_not_fount_title{
  //   color:green;
  //   text-align: center;
  // }
.cursur_title{
  cursor: pointer;
}
  .file_size{
    font-size: 100px;
    color:green;
  }
.load-more-btn{
  background-image: linear-gradient(to right, #2b6e53, #188241);
  color:white;
  border:1px solid  #188241;
  border-radius: 10px;
  padding: 10px 20px;
}
  .photoGallery-gridPage {
    width: 90%;
    max-width: 1400px;
    margin: auto;
    padding: 0px 0px;
  }
  .photo_gallery_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  .HeadingSection {
    display: flex;
    justify-content: center;
    padding: 20px;
    h2 {
      font-family: Noto Sans;
      font-size: 40px;
      font-weight: 400;
      line-height: 54.48px;
      text-align: center;
    }
  }

  .gallery_text {
    display: none;
    text-align: center;
    color: white; /* Adjust the text color as needed */
  }
  .gallery_sliding_container:hover .gallery_text {
    display: block;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Optional: Add a background for better readability */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 112px;
  }
  .gallery_sliding_heading {
    color: #fff;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .gallery_sliding_container::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ); /* Adjust the opacity (0.5) as needed */
  }

  .discover_btn {
    border-radius: 0.5rem;
    background: #fff;
    color: #212121;
    font-family: "Noto Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #fff;
    padding: 10px 20px;
  }

  .gallery_section {
    color: #212121;
    font-family: "Noto Sans";
    font-weight: 700;
  }

  .container-element {
    display: grid;
    grid: auto / repeat(6, 1fr);
    gap: 16px;
  }
  .grid-item {
    padding: 1rem;
    font-size: 30px;
    text-align: center;
    grid-column: span 3;
    height: 350px;
    border: 1px solid gray;
    border-radius: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
  }
  .container-element .grid-item:nth-child(5n),
  .container-element .grid-item:nth-child(5n-1),
  .container-element .grid-item:nth-child(5n-2) {
    grid-column: span 2;
  }

  .grid-item {
    text-align: center; /* Align text content horizontally */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center items horizontally */
    align-items: center;
    transition: filter 0.5s ease;
    position: relative;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add box shadow */
  }
  .grid-item:hover {
    filter: grayscale(100%);
  }

  // .overlay {
  //   border-radius: 20px;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.5);
  //   color: white;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   opacity: 0;
  //   transition: opacity 0.5s ease;
  // }

  // .grid-item:hover .overlay {
  //   opacity: 1;
  // }
}

// Upper section

.photoGallery-search-field {
  display: flex;
  justify-content: center;

  input {
    width: 400px;
    padding: 8px;
    border: none;
    outline: none;
    padding-left: 30px;
    background-color: transparent;
    background-image: url("../../asserts/Athlete/searchInput.png");
  }

  img {
    background: linear-gradient(to right, white 50%, transparent 50%);
  }
}

.photoGallery-heading {
  color: #fff;
  text-align: center;
  font-family: Koulen;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem;
}

.slider-container-photoGallery {
  margin-top: 0px;
  padding-top: 0px;
  position: absolute;
  bottom: -50px;
  left: 40px;
}

@media (max-width: 1290px) {
  .photo-slider-container {
    padding: 5px;
  }
}

@media (max-width: 600px) {
  .container-element {
    grid-template-columns: 1fr !important;
  }
  .photoGallery-search-field {
    input {
      width: 200px;
    }
  }
}

.container-element {
  .grid-item {
    position: relative;
    width: 100%;
    height: 300px; // Adjust the height as needed
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      // background-color: rgba(0, 0, 0, 0.6); // Semi-transparent background
      color: white;
      text-align: center;
      opacity: 0;
      transition: opacity 0.3s ease;
      font-size: 1.5rem;
    }

    &:hover {
      .overlay {
        opacity: 1;
      }
    }
  }
}
