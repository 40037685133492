.nameHeading-with-ast {
  font-family: Noto Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 6px;
  text-align: left;
  color: black;
  padding-bottom: 1.5rem;
  span {
    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 500;
    // line-height: 26px;
    text-align: left;
    color: red;
  }
}
.formBntext{
  width: 85%;
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  border: none;
 // box-shadow: 0px 5px rgba(213, 215, 216, 0.21);
}
.sportsDetBox{
  border-radius: 5px;
  background-color: #f8f9fa
}

.form-personal-details-main-container {
  // height:100vh;
  width: 100%;
  background-color: white;
  padding-left: 25px;
  padding-right: 10px;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 30px;
}
.form-personal-details-header {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-weight: 600;
  font-size: 20px;
}

.form-personal-details-basic-information {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}
.form-personal-details-basic-information div {
  display: flex;
  flex-direction: column;
  label {
    padding-bottom: 10px;
  }
  input {
    border: none;
    padding: 10px;
    background-color: #f8f9fa;
  }
  img {
    margin-bottom: 10px;
  }
}



.form-personal-details-basic-information > div:nth-child(4) {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 4;
  grid-column-end: 5;
}



.email-password-1 {
  max-width: 800px;
}


.form-personal-details-address div {
  display: flex;
  flex-direction: column;
}

@media (max-width: 792px) {
  .form-personal-details-basic-information {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .form-personal-details-basic-information > div {
    display: flex;
    //flex-direction: column-reverse;
  }
  // .form-personal-details-email-password {
  //     grid-template-columns: repeat(1, 1fr);

  // }
  .form-personal-details-address {
    // display: grid;
    grid-template-columns: 1fr; /* or any desired column sizing */
    grid-auto-rows: auto;
    // grid-template-columns: repeat(1, 1fr);
  }
  .form-personal-details-basic-information {
    // grid-template-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    // flex-direction: column;
  }
  .form-personal-details-basic-information > div:nth-child(6) {
    grid-row-start: auto;
    grid-row-end: auto;
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .form-personal-details-basic-information > div:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

// sport

.form-sport-details-container {
  padding-left: 40px;
  // padding-top: 50px;
  padding-bottom: 50px;
}

.form-sport-details-header {
  font-size: 20px;
  font-weight: 600;
}
.form-sport-details {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  // padding-left: 40px;
  // padding-top: 30px;
}
.form-sport-details > div > img {
  padding-bottom: 15px;
}
@media (max-width: 792px) {
  .form-sport-details {
    grid-template-columns: repeat(1, 1fr);
  }
  .formBntext{
    margin-left: 0px;
  }
  form-personal-details-basic-information div input{
    width: 90%;
  }
}
// bank details

.form-bank-details-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  // justify-items: center;
}

.bd-1-input {
  width: auto;
}
@media (max-width: 792px) {
  .form-bank-details-fields {
    grid-template-columns: repeat(1, 1fr);
  }
  .nameHeading-with-ast{
    font-size: 15px;
  }
  .view-image-button {
    margin-left: 10px;
  }
}

.view-image-container{
display: inline-flex;
justify-content: center;

div{
  
}

  .view-image-image{
    width: 100px;
    // border: 1px solid gray;
    border-radius: 5px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    img{
      width: 100px;
      display: block;
    }

  }
  
 



    .view-image-button {
      width: auto;
      padding: 5px 10px;
      //height: 40px;
      font-size: 15px;
      color: white;
      //box-shadow: 0 4px 8px rgba(80, 205, 137, 0.6);
    
      //background-color: #5d41af;
      background-color: #0041C2 ;
      border: none;
      border-radius: 5px;
      margin-left: 15px;
      margin-top: 50px;
      height: 35px;

    
  }

 
}

.bd-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.uploaded-documents .document {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.document-name {
  flex: 1;
  color: black;
}

.document-view {
  display: flex;
  align-items: center;
  gap: 10px;
}

.document-image {
  max-width: 100px;
  max-height: 100px;
}

.view-button {
  //background-color: #5d41af;
  background-color: #0041C2;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 4px;
  width: auto;
  font-size: 15px;
}

.viewPhotoBtn{
  
  background-color: #0041C2;
  //border-color: #0041C2;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  width: 70px;
  font-size: 15px;
  margin-left: 25px;
}
