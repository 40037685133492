.viewForm-btn {
   // margin-left: 10px;
}
.previous_btn {
  background-color: #e6e0ff;
  color: #102c56;
  border: 1px solid #e6e0ff;
  border-radius: 0.375rem;
  font-family: "Noto Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625rem; /* 130% */
  padding:8px 15px;
  margin-right: 1rem;
}

.previous_btn:disabled {
  background-color: #d9d6e7;
  cursor:not-allowed; 
}

.save-btn {
  margin: 0rem 1rem 0rem 1rem;
 }

@media (max-width: 880px) {
    .register-as-page-container {
      flex-direction: column;
    }
    .left-registration-conatiner {
      max-width: 100%;
      min-width: 100%;
    }
    .backAndnext-Btn {
      position: static;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .back-btn,
  .next-btn {
   margin: 0rem 1rem orem 1rem;
  }
 
    .register-as-page-right-container {
      margin-top: 0px;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .modalBox{
    height: 250px;
    box-shadow: #dbdaddb2;
  }
  .modalText{
    margin-left: 20px;
    margin-top: 50px;
    font-weight: 600;
  }
  .buttonDiv{
    margin-top:50px;
    margin-left: 20px;
    text-align: right;
    margin-right: 20px;
  }
  .noBtn{
  background-color: #fff;
  color: #422da8;
  margin-right: 20px;
  width: 70px;
  }
  .noBtn:hover{
    color: #efefef;
    //background-color: #422da8;
    background-color: #a52424;
    border-color: #a52424;
  }
  .yesBtn{
    color: #efefef;
    background-color: #422da8;
    border-color:  #422da8;
    width: 70px;
  }
  .yesBtn:hover{
    color: #fff;
    background-color: #1c7032;
    border-color: #1c7032;
  }
  @media (min-width: 992px) {
    .col-lg-9 {
        flex: 0 0 auto;
        width: 74%;
    }
}
@media (max-width: 720px){
  .marginL{
    margin-left: -17px;
  }
  .save-btn{
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left:0px;
    margin-right: 10px;
  }
}