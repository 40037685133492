.mission-vision-container {
  background-image: url("../../asserts/MissionVision/banner-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* Center the background image */
  width: 100%;
  min-height: 80vh;

}
.mission-right-container {
  background-image: url("../../asserts/AboutUs/about-us-vector.png");
  background-repeat: no-repeat;
  background-position: 101% 100%;
  background-size: min(43%, 600px) auto;
}
.slider-cont {
  margin-top: 0px;
  padding-top: 0px;
  position: absolute;
  bottom: 160px;
  left: 40px;
}

.mession_heading p{
  font-size: 5rem;
  margin-top: 435px;
  font-weight: 400;
  font-family: "Koulen", sans-serif;
}

// .col-lg-6 {
//   padding-top: 50px;
// }
.vision-img {
  max-width: 70%;
  margin-left: 15%;
  padding-bottom: 4%;
}
.content-mission-vision {
  max-height: 500px;
  padding-top: 260px;
}

.mission-vision-main-container {
  background: linear-gradient(176.07deg, #001710 15.63%, #074614 97.67%);
  max-height: 60%;
}

.mission-lower-heading {
  color: #fff;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  padding-top: 150px;
}
.mv-lower-rl {
  padding-top: 80px;
  font-size: 18px;
}
.mission_section {
  background: url(../../asserts/MissionVision/world_doted.png) no-repeat;
  background-position: 110% 10%;
  background-size: 59% 40%;
}
.mission-vision-bottom-section {
  display: flex;
  justify-content: space-between;
}
.mv-content {
  font-size: 20px;
  padding-top: 10px;
}

.Introduction-right-girl-playing {
  max-width: 92%;
  margin-top: -90px;
  margin-left: 65px;
}
.mv-bottom-right {
  margin-left: 70px;
}
.girlRacing-img {
  max-width: 97%;
  max-height: 100%;
}

.font-weight-bold {
  color: #212121;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.font-vision-header {
  color: #212121;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 108.333% */
}
.vision-heading {
  color: #212121;
  font-family: "Noto Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}
.font-vision-content {
  color: #212121;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding-top: 10px !important;
}

.utility-content {
  color: #212121;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 144.444% */
}

@media (min-width: 675px) {
  .img_container {
    display: grid;
    grid-template-columns: 430px 1fr;
    margin-bottom: 50px;
  }
}

@media (max-width: 860px) {
  .Introduction-right-girl-playing img {
    max-width: 80%;
    margin-top: -50px;
    margin-left: 50px;
  }
  .mv-lower-rl {
    padding-top: 120px;
  }
}

@media (max-width: 674px) {
  .img_container img {
    width: 100%;
  }

  .Introduction-right-girl-playing {
    margin-top: 0;
    max-width: 100%;
    margin-left: 0;
  }

  .mission-vision-bottom-section {
    flex-direction: column-reverse;
  }

  .img_container .right_side_img img:first-child {
    width: 100%;
  }

  .img_container img:first-child {
    display: none;
  }
}

@media (max-width: 274px) {
  .img_container {
    width: 100%;
  }
}

.mixed-images {
  background-position: 100% 7%;
  background-size: 50% 50%;
  margin-top: 40px !important;
}
