.latest-news-container {
  background-image: url("../../asserts/LatestNews/latest-news-bg.png");
  min-height: 580px;
  width: 100%;
  background-repeat: no-repeat;
}

.heading-text p {
  font-size: 42px;
  margin-top: 280px;
  font-weight: 600;
}

.latest-news-contents,
.top-news-contents {
  // margin-left: 50px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.trending-now-heading,
.top-news-heading {
  color: #313131;
  font-family: "Noto Sans";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.8125rem; /* 127.083% */
}

.readNow-btn {
  border-radius: 0.5rem;
  color: white;
  padding: 10px 20px 10px 20px;
  font-size: 0.8rem;
  border: none;
  background: var(--33, linear-gradient(180deg, #000f0a 0%, #014f37 100%));
}

.left-img-latest-news {
  position: relative;
  max-width: 50%;
}
.left-news {
  position: absolute;
  bottom: 0;
}

.trending-now-container {
  display: flex;
  gap: 80px;
}
.right-content-container {
  max-width: 40%;
  margin-top: 15px;

  p {
    font-size: 0.955rem;
  }
}

.latest_news_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.lates_news_heading {
  color: #fff;

  text-align: center;
  font-family: "Koulen", sans-serif;
  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 6.25rem;
}
.top-news-map-contents-flex {
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 95%;
}

.top-news-map-contents {
  border: 1px solid rgb(174, 172, 172);
  border-top: none;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  position: relative;
  img {
    max-width: 100%;
  }
}

.read-now-position {
  position: absolute;
  left: 27px;
  bottom: 20px;
}

.banner-img {
  img {
    width: 520px;
    height: 300px;
    object-fit: cover;
    border-radius: 1rem;
  }
}

.map-details {
  display: flex;
  gap: 30px;
  // margin: 20px 20px 20px 30px;

  p {
    margin-top: 8px;
    color: rgb(78, 75, 75);
  }
}
.mapd-heading {
  margin: 10px 20px 20px 2px;
  color: #313131;
  font-family: "Noto Sans";
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5625rem; /* 113.636% */
}
.mapd-desc {
  margin: 10px 20px 20px 2px;
  font-family: "Noto Sans";
  font-weight: 400;
}
.mapd-btn {
  margin-bottom: 30px;
  margin-left: 30px;
}

.readNow-btn-maps {
  border-radius: 0.5rem;
  color: white;
  padding: 10px 20px 10px 20px;
  font-size: 0.8rem;
  border: none;
  background: var(--33, linear-gradient(180deg, #000f0a 0%, #014f37 100%));
}

.top_news_container {
  // border:none!important;
  border-radius: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media (max-width: 1000px) {
  .trending-now-container {
    flex-direction: column;
  }
  .right-content-container {
    max-width: 100%;
  }
  .newsImg {
    max-width: 180% !important;
  }

  .top-news-map-contents-flex {
    flex-direction: column;

    img {
      max-width: 100%;
    }
  }
}

.newsContainer {
  text-align: left;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.abc {
  div,
  h1,
  p {
    font: 760 40px/48px "Paris2024-Variable", -apple-system, Roboto, Helvetica,
      sans-serif;
  }
}

.heading-mainNews {
  color: #313131;
  font-family: Paris2024-Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 760;
  line-height: 48px; /* 120% */
}

.second-news-main {
  max-width: 600px;
}

.news-section-heading {
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 299px;
  font: 760 40px/2 Paris2024-Variable, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.newsMainSection {
  margin: 6rem 3rem 0 3rem;
  width: 90%;
  align-self: center;
}

.postion_rel {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.newsMainInnerSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  height: 500px;
}

.news_heading_prev_new_stories {
  color: #000;
  font-family: 'Noto Sans';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 65px; /* 180.556% */
}

.multipleNewsSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}

.newsTag {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  color: white;
  padding: 10px 20px;
  font-size: 24px;
  height: 64px;
  border-bottom-left-radius: 32px !important;
  text-align: center;
  font-weight: bold;
  margin-top: -2.6rem;
  width: 330px;
}

.multipleNewsInsideSectionTime {
  color: #313131;
  max-width: 144px;
  font: 700 16px/1 Lato, sans-serif;

}
.line-clamp-3{
  font-family: 'Noto Sans';
  margin-top: 2rem!important;
}
.newsInsideSectionSeparator {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.individual_news_single_heading {
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Paris2024-Variable;
  font-size: 60px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px; /* 108.333% */
}

.top-news-heading {
  font-family: Paris2024-Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.newsImageExtraTag {
  background: var(--blue-fff, #19417a);
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  right: -8px;
}

.newsInsideSectionForLeftNewsLeftMargin {
  margin-left: 3rem;
}

.newsInsideSectionForRightNews {
  margin: 3rem 3rem 0 1.5rem;
}

.newsSectionBackground {
  background-color: #19417a;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.p5 {
  padding: 2rem;
}

.marginTop {
  margin-top: 3rem;
}

.inner_news_content_heading {
  color: #313131;
  font-family: Paris2024-Variable;
  font-size: 60px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px; /* 108.333% */
}

.newsTagStyle {
  border: 1px solid var(--hhhhh, #19417a);
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 0.4rem;
  font-weight: 600;
  position: absolute;
}

.multipleNewsInsideSectionHeading {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.newsInsideSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.commonBtnPinkGradientBig {
  width: 185px;
  height: 67px;
  flex-shrink: 0;
  border-radius: 34px;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 226.087% */
  letter-spacing: 0.23px;
}

@media (max-width: 640px) {
  .newsMainInnerSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .multipleNewsSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .multipleNewsInsideSection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    place-items: center;
  }

  .multipleNewsInsideSectionHeading {
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: bold;
  }

  .multipleNewsInsideSectionTime {
    line-height: 0;
  }

  .newsMainSection {
    margin: 1rem 1rem 0 1rem;
  }

  .newsInsideSectionSeparator {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    display: flex;
    flex-direction: column-reverse;
  }

  .newsSponsors {
    display: none;
  }

  .newsSectionBackgroundHidden {
    display: none;
  }

  .p5 {
    padding: 0rem;
  }

  .newsTagStyle {
    position: relative;
    top: 1rem;
  }

  .marginTop {
    margin-top: 2rem;
  }

  .newsInsideSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    place-items: center;
    width: 350px;
  }

  .newsContainer {
    margin-bottom: 0;
  }

  .newsInsideSectionForRightNews {
    margin: 2rem 1rem 0 1rem;
  }
}

.slider-main {
  transition: transform 0.25s ease;
}

.slider-main:hover {
  transform: translateY(-1rem);
}

.slider-main:hover .content-body h5,
.slider-main:hover .content-body p {
  color: #804f9b;
}

.slider-main:hover .content-body .readnow-text {
  padding-right: 0;
  padding-left: 7rem;
  color: #804f9b;
}

///
///
///
///
///
///
.newsContainer {
  text-align: left;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.abc {
  div,
  h1,
  p {
    font: 760 40px/48px "Paris2024-Variable", -apple-system, Roboto, Helvetica,
      sans-serif;
  }
}

.heading-mainNews {
  color: #313131;
  font-family: Paris2024-Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 760;
  line-height: 48px; /* 120% */
}

.second-news-main {
  max-width: 600px;
}

.news-section-heading {
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 299px;
  font: 760 40px/2 Paris2024-Variable, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.newsMainSection {
  margin: 6rem 3rem 0 3rem;
  width: 90%;
  align-self: center;
}

.postion_rel {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.newsMainInnerSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  height: 500px;
}

.news_heading_prev_new_stories {
  color: #000;
  font-family: 'Noto Sans';
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 65px; /* 180.556% */
}

.multipleNewsSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}

.newsTag {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  color: white;
  padding: 10px 20px;
  font-size: 24px;
  height: 64px;
  border-bottom-left-radius: 32px !important;
  text-align: center;
  font-weight: bold;
  margin-top: -2.6rem;
  width: 330px;
}

.multipleNewsInsideSectionTime {
  color: #313131;
  max-width: 144px;
  font: 700 16px/1 Lato, sans-serif;
}

.newsInsideSectionSeparator {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.individual_news_single_heading {
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Paris2024-Variable;
  font-size: 60px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px; /* 108.333% */
}

.top-news-heading {
  font-family: Paris2024-Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.newsImageExtraTag {
  background: var(--blue-fff, #19417a);
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  right: -8px;
}

.newsInsideSectionForLeftNewsLeftMargin {
  margin-left: 3rem;
}

.newsInsideSectionForRightNews {
  margin: 3rem 3rem 0 1.5rem;
}

.newsSectionBackground {
  background-color: #19417a;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.p5 {
  padding: 2rem;
}

.marginTop {
  margin-top: 3rem;
}

.inner_news_content_heading {
  color: #313131;
  font-family: Paris2024-Variable;
  font-size: 60px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px; /* 108.333% */
}

.newsTagStyle {
  border: 1px solid var(--hhhhh, #19417a);
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 0.4rem;
  font-weight: 600;
  position: absolute;
}

.multipleNewsInsideSectionHeading {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.newsInsideSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.commonBtnPinkGradientBig {
  width: 185px;
  height: 67px;
  flex-shrink: 0;
  border-radius: 34px;
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 226.087% */
  letter-spacing: 0.23px;
}

@media (max-width: 640px) {
  .newsMainInnerSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .multipleNewsSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .multipleNewsInsideSection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    place-items: center;
  }

  .multipleNewsInsideSectionHeading {
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: bold;
  }

  .multipleNewsInsideSectionTime {
    line-height: 0;
  }

  .newsMainSection {
    margin: 1rem 1rem 0 1rem;
  }

  .newsInsideSectionSeparator {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    display: flex;
    flex-direction: column-reverse;
  }

  .newsSponsors {
    display: none;
  }

  .newsSectionBackgroundHidden {
    display: none;
  }

  .p5 {
    padding: 0rem;
  }

  .newsTagStyle {
    position: relative;
    top: 1rem;
  }

  .marginTop {
    margin-top: 2rem;
  }

  .newsInsideSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    place-items: center;
    width: 350px;
  }

  .newsContainer {
    margin-bottom: 0;
  }

  .newsInsideSectionForRightNews {
    margin: 2rem 1rem 0 1rem;
  }
}

.slider-main {
  transition: transform 0.25s ease;
}

.slider-main:hover {
  transform: translateY(-1rem);
}

.slider-main:hover .content-body h5,
.slider-main:hover .content-body p {
  color: #804f9b;
}

.slider-main:hover .content-body .readnow-text {
  padding-right: 0;
  padding-left: 7rem;
  color: #804f9b;
}


//single page css


.newsContainer {
  text-align: left;
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.abc {
  div,
  h1,
  p {
    font: 760 40px/48px "Paris2024-Variable", -apple-system, Roboto, Helvetica, sans-serif;
  }
}

.heading-mainNews {
  color: #313131;
  font-family: Paris2024-Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 760;
  line-height: 48px; // 120%
}

.second-news-main {
  max-width: 600px;
}

.news-section-heading {
  background: var(--hhhhh, linear-gradient(90deg, #19417a 0%, #0b9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  max-width: 299px;
  font: 760 40px/2 Paris2024-Variable, -apple-system, Roboto, Helvetica, sans-serif;
}

.newsMainSection {
  margin: 6rem 3rem 0 3rem;
  width: 90%;
  align-self: center;
}

.postion_rel {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.newsMainInnerSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
  height: 500px;
}

.news_heading_prev_new_stories {
  color: #000;
  font-family: 'Noto Sans';
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 65px; // 180.556%
}

.multipleNewsSection {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}

.newsTag {
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
  color: white;
  padding: 10px 20px;
  font-size: 24px;
  height: 64px;
  border-bottom-left-radius: 32px !important;
  width: 330px;
  text-align: center;
  font-weight: bold;
  margin-top: -2.6rem;
}

.multipleNewsInsideSectionTime {
  color: #313131;
  max-width: 144px;
  font: 700 16px/1 Lato, sans-serif;
}

.newsInsideSectionSeparator {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.individual_news_single_heading {
  background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Noto Sans';
  font-size: 60px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px; // 108.333%
  
}

.top-news-heading {
  font-family: Paris2024-Variable;
  font-size: 40px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px;
  background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.newsImageExtraTag {
  background: var(--blue-fff, #19417A);
  height: 100%;
  width: 8px;
  position: absolute;
  top: 0;
  right: -8px;
}

.newsInsideSectionForLeftNewsLeftMargin {
  margin-left: 3rem;
}

.newsInsideSectionForRightNews {
  margin: 3rem 3rem 0 1.5rem;
}

.newsSectionBackground {
  background-color: #19417A;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.p5 {
  padding: 2rem;
}

.marginTop {
  margin-top: 3rem;
}

.inner_news_content_heading {
  color: #313131;
  font-family: Paris2024-Variable;
  font-size: 60px;
  font-style: normal;
  font-weight: 760;
  line-height: 65px; // 108.333%
}

.newsTagStyle {
  border: 1px solid var(--hhhhh, #19417A);
  background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  padding: 0 1rem;
  border-radius: 0.4rem;
  font-weight: 600;
  position: absolute;
}

.multipleNewsInsideSectionHeading {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.newsInsideSection {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.commonBtnPinkGradientBig {
  width: 185px;
  height: 67px;
  flex-shrink: 0;
  border-radius: 34px;
  background: var(--hhhhh, linear-gradient(90deg, #19417A 0%, #0B9444 100%));
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; // 226.087%
  letter-spacing: 0.23px;
}

@media (max-width: 640px) {
  .newsMainInnerSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .multipleNewsSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .multipleNewsInsideSection {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    place-items: center;
  }

  .multipleNewsInsideSectionHeading {
    font-size: 18px;
    line-height: 1.5rem;
    font-weight: bold;
  }

  .multipleNewsInsideSectionTime {
    line-height: 0;
  }

  .newsMainSection {
    margin: 1rem 1rem 0 1rem;
  }

  .newsInsideSectionSeparator {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    display: flex;
    flex-direction: column-reverse;
  }

  .newsSponsors {
    display: none;
  }

  .newsSectionBackgroundHidden {
    display: none;
  }

  .p5 {
    padding: 0rem;
  }

  .newsTagStyle {
    position: relative;
    top: 1rem;
  }

  .marginTop {
    margin-top: 2rem;
  }

  .newsInsideSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    place-items: center;
    width: 350px;
  }

  .newsContainer {
    margin-bottom: 0;
  }

  .newsInsideSectionForRightNews {
    margin: 2rem 1rem 0 1rem;
  }
}

.slider-main {
  transition: transform 0.25s ease;
  &:hover {
    transform: translateY(-1rem);

    .content-body {
      h5,
      p {
        color: #804f9b;
      }

      .readnow-text {
        padding-right: 0;
        padding-left: 7rem;
        color: #804f9b;
      }
    }
  }
}
